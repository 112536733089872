#service_landng {
    padding-top: 250px;
}

.vlu-spacmange {
    margin-top: -245px;
}
.industrl .sply-chn {
    margin-top: -110px;
}

@media screen and (min-width:320px) and (max-width:767px){
    .industrl .sply-chn {
        margin-top: 140px;
    }
    
}
@media screen and (min-width:768px) and (max-width:1023px){
    .industrl .sply-chn {
        margin-top: 140px;
    }
}


@media screen and (min-width:320px) and (max-width:768px){
    #service_landng {
        padding-top: 150px;
        padding-bottom: 70px;
    }
    h3.srvc-sub-ttl {
        text-align: center;
        font-size: 18px;
        line-height: 32px;
        color: #fff;
    }
    
}

@media screen and (min-width:1024px) and (max-width:1279px){
    .vlu-spacmange {
        margin-top: -200px;
    }
    .indstry-factry {
        margin-top: -140px;
    }
    h3.srvc-sub-ttl {
        text-align: left;
    }
    .industrl .sply-chn {
        margin-top: -123px;
    }
}
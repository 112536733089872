.awards_main
{
    padding-top: 135px;
    padding-bottom: 240px;
}
.awrds_row
{
    margin: 0px;
}
.awrd-hd-sec
{
    text-align: center;
    margin-bottom: 20px;
}
.awrd-ttl
{
    font-family:'Montserrat';
    font-weight: 500;
    font-size: 30px;
    line-height:39px;
    color: #06172F;
    margin-bottom: 14px;
}
.awrd-sub-ttl
{
    font-family:'Open Sans';
    font-weight: 400;
    font-size: 18px;
    line-height:32px;
    color: #06172F;
}
.awrd-box
{
    text-align: center;
}

.awrds-sld .slick-slide {
    text-align: center;
}
.awrds-sld img.awrd__img {
    width: auto !important;
    max-height: 77px;
}
.awrds-sld .slick-arrow:hover 
{
    background-color: #c13529;
    transition: 0.3s all ease-in;
    border-color: #c13529;
}
.awrds-sld .slick-prev:hover:before
{
    background-image: url('../img/left-arrow.png');
}
.awrds-sld .slick-next:hover:before
{
    background-image: url('../img/right-arrow.png');
}
.awrds-sld .slick-prev {
    left: 520px;
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #000;
    border-radius: 50%;
    z-index: 1;
    top: auto;
    bottom: -130px;
}
.awrds-sld .slick-prev:before
{
    content: '' ;
    background-image: url('../img/black-left-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;          
}
.awrds-sld .slick-next {
    
    right: 520px;
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #000;
    border-radius: 50%;
    top: auto;
    bottom:-130px;
    z-index: 1;
}
.awrds-sld .slick-next:before
{
    content: '' ;
    background-image: url('../img/black-right-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;
}
.awrds-sld .slick-track {
    display: flex;
    align-items: center;
}
@media screen and (min-width:320px) and (max-width:768px){
    .awrds-sld .slick-next {
        bottom: -145px;
        top: auto;
        left: 53%;
        right: 0px;
    }
    .awrds-sld .slick-prev {
        bottom: -145px;
        top: auto;
        right: 52%;
        left: auto;
    }
    .awards_main {
        padding: 50px 20px 170px 20px;
    }
}

@media screen and (min-width:768px) and (max-width:1024px){
    .awrds-sld .slick-next {
        right: 40%;
        bottom: -145px;
    }
    .awrds-sld .slick-prev {
        left: 42%;
        bottom: -145px;
    }
}
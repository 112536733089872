.case_Study_name
{   
    padding-left:80px;
    padding-bottom: 190px;
    background: rgb(6,23,47);
    background: linear-gradient(90deg, rgba(6,23,47,1) 76%, rgba(255,255,255,1) 76%);
    display: none;
}
.cas-col
{
    padding: 0px;
}
.case_Study_name .row
{
    margin:0px;
}
.cs-ttl
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 89px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}

/*-------------------------------------------------------------------- Slider Css Start Here */
.cs-sld {
    margin-top: 50px;
}
.cs-sld .slick-list {
    padding:  0px 100px 0px 0px !important;
}
.cs-sld .slick-arrow:hover 
{
    background-color: #fff;
    transition:0.3s all ease-in;
}
.cs-sld .slick-prev:hover:before
{
    background-image: url('../img/black-left-arrow.png');
}
.cs-sld .slick-next:hover:before
{
    background-image: url('../img/black-right-arrow.png');
}
.cs-sld .slick-prev {
    left: 0px;
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #fff;
    border-radius: 50%;
    z-index: 1;
    top: auto;
    bottom: -130px;
}
.cs-sld .slick-prev:before
{
    content: '' ;
    background-image: url('../img/left-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;          
}
.cs-sld .slick-next {
    
    left: 80px;
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #fff;
    border-radius: 50%;
    top: auto;
    bottom: -130px;
    z-index: 1;
}
.cs-sld .slick-next:before
{
    content: '' ;
    background-image: url('../img/right-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;
}

.cs-item
{
    width: 100%;
    float: left;
}

.cs-col-1
{
    width:38%;
    float: left;
    position: relative;
    overflow: hidden;
}
.cs-img
{
    width:100%;
}
.cs-col-1::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transform: translateX(0%);
}

.slick-slide.slick-active.slick-current .cs-col-1::after 
{
    transform: translateX(100%);
    background: #06172f;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 2s transform ease;
    transition-delay: 1s;
}
.slick-slide.slick-active.slick-current + .slick-slide .cs-col-1::after 
{
    transform: translateX(100%);
    background: #fff;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    transition: 2s transform ease;
    transition-delay: 1s;
}
/* .cs-col-1 img {
    width: 100%;
} */
.cs-col-2
{
    width: 37%;
    float: left;
    padding-left: 50px;
}
.cs-para
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 130%;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}
.cs-txt
{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}


@media only screen and (min-device-width: 1910px) and (max-device-width: 1920px) {
    .case_Study_name {
        padding-left: 355px;
        background: linear-gradient(90deg, rgba(6,23,47,1) 83%, rgba(255,255,255,1) 83%);
    }
    .cs-col-1 {
        width: 33%;
    }
    .cs-col-2 {
        width: 32%;
        padding-left: 50px;
    }
}
@media only screen and (min-device-width: 1580px) and (max-device-width: 1600px) {
    .case_Study_name {
        padding-left: 200px;
        background: linear-gradient(90deg, rgba(6,23,47,1) 79%, rgba(255,255,255,1) 79%);
    }
    .cs-col-1 {
        width: 33%;
    }
    .cs-col-2 {
        width: 32%;
        padding-left: 50px;
    }
}
@media only screen and (min-device-width: 1420px) and (max-device-width: 1440px) {
    .case_Study_name {
        
        padding-left: 125px;
        background: linear-gradient(90deg, rgba(6,23,47,1) 83%, rgba(255,255,255,1) 83%);

    }
    .cs-col-1 {
        width: 33%;
    }
    .cs-col-2 {
        width: 32%;
        padding-left: 50px;
    }
}
@media only screen and (min-device-width: 1270px) and (max-device-width: 1280px) {
    .case_Study_name {
        padding-left: 44px;
    }
}
/* .case_Study_name.sec-pd-tb {
    display: none;
} */
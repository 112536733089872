.life-capricorn
{
    width:100%;
}
.lc-row
{
    margin: 0px;
}
.lc-hd-col > div
{
    width: 13%;
    margin: auto;
}
.lc-hd-col .dot {
    width: 12px;
    height: 12px;
    background: #fff;
    margin-right: 15px;
    float: left;
    border-radius: 50%;
    margin-top: 3px;
}
.lc-hd-col .sec-name-ttl {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.015em;
    color: #fff;
    margin-bottom: 15px;
}
.lc-hd
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 70px;
    line-height: 70px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    margin-bottom: 13px;
}
.lc-tabs-col #myTab {
    border: none;
    justify-content: center;
}
.lc-tabs-col .nav-tabs li a
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    padding: 35px 0px 8px 0px;
    margin-right: 37px;
    text-decoration: none;
}
.opport
{
    margin-right: 0px !important;
}
.lc-tabs-col .nav-tabs li a:hover
{
    border:none;
}
.lc-tabs-col .nav-tabs li a.active
{
    border: 0px;
    border-bottom: 2px solid #E5E5E5;
    background: transparent;
}
#myTabContent .tab-pane
{
    padding: 40px 0px 0px 0px;
}
.cul-col2
{
    position: relative;
}
.cul-col3 {
    padding-top: 170px;
}

.cul-para
{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 28.8px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    padding-top: 50px;

}
.cul-ttl {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 201.13px;
    line-height: 257px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    position: absolute;
    left: -131px;
    top: 115px;
    z-index: 1;
}

img.conversn-img {
    position: absolute;
    top: 55%;
    right: 50px;
    left: auto;
}
img.packge-img {
    position: absolute;
    top: 50px;
    left: 125px;
    right: auto;
}

.opp-ttl {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 150px;
    line-height: 257px;
    display: flex;
    align-items: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    position: absolute;
    left: -305px;
    top: 115px;
    z-index: 1;
}
.reveal
{
  position: relative;
  overflow: hidden;
  white-space: nowrap;
  
}
.prlx_img1
{
    transform: translateY(-30);
}
.prlx_img2
{
    transform: translateY(30);
}
.rvl-layer{
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #095792;
    top: 0px;
    left: 0px;
}
.cltr-lrn-mor {
    position: absolute;
    bottom: 44px;
    text-align: center;
    margin: auto;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
}
.grdth-lrn-mor {
    position: absolute;
    bottom: 15px;
    text-align: center;
    margin: auto;
    left: 50%;
    right: 50%;
    transform: translate(-50%, -50%);
}
.lc-tabs-col {
    padding-top: 20px;
}

@media screen and (min-width:1024px) {
    .mbl-btn {
        display: none   
    }
}

@media screen and (min-width:320px) and (max-width:1023px) {
    .desk-btn {
        display: none
    }   
}

@media screen and (min-width:320px) and (max-width:767px) {
    .cltr-lrn-mor {
        bottom: 32px;       
    }
}

@media screen and (min-width:768px) and (max-width:1023px) {
    div#opportunities .cltr-lrn-mor {
        bottom: 40px;
    }
    div#culture .cul-col3 {
        padding-top: 67px;
    }
    div#growth .cul-col3 {
        padding-top: 102px;
    }
    div#opportunities .cul-col3 {
        padding-top: 110px;
    }
}
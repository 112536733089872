/* .vlus_main
{
    
} */

.value-sldr {
    padding: 0;
}
.value-sldr button.slick-arrow {
    display: none !important;
}
.vlue-col1 .slick-prev {
    right: auto;
    bottom: -110px;
    left: 0;
}
.vlue-col1 .slick-next {
    left: 85px;
    right: auto;
    bottom: -110px !important;
}
.vlus_row
{
    margin: 0px;
}
.vlue-col1
{
    background-color:#CA2D1D;
    padding:100px 100px 50px 80px;
    height: 680px;
}
.vlue-ttl
{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #fff;
    margin-bottom: 47px;
}
.vlue-sub-ttl
{
    color: #fff;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    display: block;
    padding-bottom: 10px;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 10px;
}
.vlue-para
{
    font-family: 'open sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: #fff;
}

.vlue-sld .slick-arrow:hover 
{
    background-color: #fff;
    transition:0.3s all ease-in;
}
.vlue-sld .slick-prev:hover:before
{
    background-image: url('../img/black-left-arrow.png');
}
.vlue-sld .slick-next:hover:before
{
    background-image: url('../img/black-right-arrow.png');
}
.vlue-sld .slick-prev {
    left: 0px;
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #fff;
    border-radius: 50%;
    z-index: 1;
    top: auto;
    bottom: -105px;
}
.vlue-sld .slick-prev:before
{
    content: '' ;
    background-image: url('../img/left-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;          
}
.vlue-sld .slick-next {
    
    left: 80px;
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #fff;
    border-radius: 50%;
    top: auto;
    bottom: -105px;
    z-index: 1;
}
.vlue-sld .slick-next:before
{
    content: '' ;
    background-image: url('../img/right-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;
}



.vlue-col2 {
    padding: 0px;
    height: 680px;
    padding-bottom: 40px;
}
.vlue-col2 .vlue_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media only screen and (min-device-width: 1910px) and (max-device-width: 1920px) {
    .vlue-col1
    {
        padding-left:360px;
    }
}
@media only screen and (min-device-width: 1590px) and (max-device-width: 1600px) {
    .vlue-col1
    {
        padding-left:207px;
    }
}
@media only screen and (min-device-width: 1430px) and (max-device-width: 1440px) {
    .vlue-col1
    {
        padding-left:125px;
    }
}
@media only screen and (min-device-width: 1270px) and (max-device-width: 1280px) {
    .vlue-col1
    {
        padding-left:40px;
    }
}

@media screen and (min-width:320px) and (max-width:768px){
    #our-values .vlue-col1 {
        padding: 50px 20px 130px 20px;
        height: auto;
        text-align: center;
        margin-top: -10px;
    }
    #our-values .vlue-sld .slick-next {
        right: 30%;
        left: auto;
    }
    #our-values .vlue-sld .slick-prev {
        left: 30%;
    }
    #our-values .mob-img .vlue_img {
        height: 337px;
        width: 100%;
        object-fit: cover;
    }   
    div#our-values .slick-slider {
        margin-right: 0;
    }
    .vlue-col1 .slick-next {
        top: auto;
        left: 53%;
        right: 0px;
        bottom: -110px;
    }
    .vlue-col1 .slick-prev {
        top: auto;
        right: 52%;
        left: auto;
        bottom: -110px;
        
    }
}

@media screen and (min-width:768px) and (max-width:1023px){
    #our-values .vlue-sld .slick-next {
        right: 40%;
        left: auto;
    }
    #our-values .vlue-sld .slick-prev {
        left: 41%;
    }
    .vlue-col1 .slick-next {
        right: 40%;        
    }
    .vlue-col1 .slick-prev {
        left: 42%;
    }
}
.visible-hde
{
    visibility: hidden;
}
.air_freight_services
{
    position: relative;
}
.air-srvc-col1
{
    padding-top: 130px;
    z-index: 1;
}
.air_srvc_ttl
{
    font-family: 'Montserrat';
    font-size: 30px;
    line-height: 39px;
    color: #fff;
    margin-bottom: 30px;
}
.air_srvc_para
{
    font-family: 'Open Sans';
    font-size: 18px;
    line-height: 32px;
    font-weight: 300;
    color: #fff;
    width: 83%;
}
.air-srvc-col2 {
    z-index: 1;
}
.door
{
    padding-right: 20px;
}
.large-ship
{
    margin-top: 50px;
    padding-left: 20px;
}
.small-ship
{
    margin-top: -50px;
    padding-right: 20px;
}
.stand-air
{
    padding-left: 20px;
}
.air_servc_bg
{
    position: absolute;
    top: auto;
    bottom: 62px;
    width: 40%;
}
.land_servc_bg
{
    position: absolute;
    top: auto;
    bottom: 0;
    width: 30%;
    left: 165px;
}
.small-sip-spacmange {
    margin-top: -50px !important;
}
.cross-drock-spacmange {
    padding-top: 0;
}
.last-mile-spacmange {  
    padding-top: 0;
    margin-top: -100px !important;
}
.bound-txt h5.serv_sub-hd_ttl {
    margin-bottom: 20px;
}

@media screen and (min-width:320px) and (max-width:768px){
    .air_freight_services {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .air-srvc-col1 {
        padding-top: 0px;
    }
    h3.air_srvc_ttl {
        text-align: center;
    }
    .air_srvc_para {
        font-size: 16px;
        line-height: 28px;
    }
    .door {
        padding-top: 20px;
        padding-right: 12px !important;
    }
    .large-ship {
        margin-top: 0px !important;
        padding-top: 20px;
        padding-left: 10px!important;
    }
    .small-ship {
        padding-right: 12px !important;
        margin-top: 0px !important;
        padding-top: 20px;
    }
    .stand-air {
        padding-left: 12px !important;
        padding-top: 20px;
    }
    .air-srvc-col2 .visible-hde {
        display: none;
    }
    .automotive_service h5.serv_sub-hd_ttl {
        text-align: center;
    }
    .serv_cap_icn_lst {
        font-size: 16px !important;
    }
    .automotive_service .serv_cap_icn_lst {
        padding-left: 50px;
    }
    .fmcg_services .serv_clmn_gap_lft, .fmcg_services .serv_clmn_gap_rgt {
        padding-right: 15px !important;
        padding-left: 10px;
    }
    .pharma_services .serv_img_overlap {
        padding-left: 0px !important;
    }
    .serv_clmn_gap_lft {
        padding-right: 0px !important;
    }
    .telecom_services .serv_cap_icn_lst {
        padding-left: 40px;
    }
    .engineering-sec {
        padding-bottom: 30px;
    }
    

}

@media screen and (min-width:1024px) and (max-width:1279px){
    .compliance .license-spacmange {
        margin-top: -95px !important;
    }
    .cross-drock-spacmange {
        margin-top: -21px !important;
    }
}

@media screen and (min-width:768px) and (max-width:1023px){
    .automotive_service .serv_cap_icn_lst {
        padding-left: 50px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    
}


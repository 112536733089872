.found-txt span{
    color: #c13529;
}
.search-bg {
    background-color: #EFEDEE;
}
.result-error h2{
    text-align: center;
    padding-top: 60px;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 30px;
    height: 39px;
    color: #06172F;
    letter-spacing: -0.015em;
}

.srch-pg-rw {
    padding-top: 70px;
}

.serch-result-dv .blog-inside-page img {
    height: 250px;
    width: auto;
}

.blg-srch-rsult .blog-inside-page:nth-child(even) {
    background-color: #06172f;
    color: #fff;
}
.blg-srch-rsult .blog-inside-page {
    background-color: #fff;
    padding: 50px;
    text-decoration-color: #06172f;
}
.blg-srch-rsult .blog-inside-page:nth-child(even) h1,  .blg-srch-rsult .blog-inside-page:nth-child(even) a {
    color: #fff !important;
    font-family: 'Montserrat';
    text-decoration-color: #fff;
}

.blg-srch-rsult .blg-view-btn {
    border: none;
}




.srvc-resul-pg .blog-inside-page {
    background-color: #06172f;
    color: #fff !important;
}
.srvc-resul-pg .blog-inside-page h1 {
    color: #fff;
}

.srvc-resul-pg .blog-inside-page:nth-child(even) {
    background-color: #fff;
    color:#06172f !important;
}

.srvc-resul-pg .blog-inside-page:nth-child(even) h1, .srvc-resul-pg .blog-inside-page:nth-child(even) a {
    color:#06172f !important;
    font-family: 'Montserrat' !important;
    text-decoration-color: #06172f !important;
}

.srvc-resul-pg .blog-inside-page a {
font-family: 'Montserrat';
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.015em;
    color: #fff;
    padding-bottom: 6px;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 5px ;
    text-decoration-color: #fff !important;
}

.serch-result-dv p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    font-family: 'Open Sans';
}

h3.found-txt {
    padding: 40px 0px;
    font-size: 40px;
    color: #06172f;
    font-family: 'Montserrat';
    font-weight: 500;
}

.blg-srch-rsult {
    width: 80%;
    margin: auto;
}
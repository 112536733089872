.thnk
{
    display: none;
    font-family: 'Open Sans';
    text-align: center;
    font-size: 25px;
    font-weight: 400;
    margin-top: 8%;
}
.thnk_show
{
    display: block;
}
.form_hide
{
    display: none;
}


ul.contact-list {
    padding: 0px;
    list-style: none;
}
.cont-ttl
{
    margin-bottom: 20px;
}
.cont-col1
{
    display:flex;
    align-items: center;
}
p.cont-sub-ttl {
    font-family: 'open sans';
    font-weight: 400;
    font-size: 22px;
    line-height: 30px;
    color: #fff;
    margin-bottom: 30px;
}
li.address{
    font-family: 'Open Sans';
    font-weight: 300;
    color: #fff;
    letter-spacing: 0.6px;
    margin-bottom: 10px;
    font-size: 18px;
}
.tele-no {
    font-family: 'Open Sans';
    font-weight: 300;
    color: #fff;
    letter-spacing: 0.6px;
    font-size: 18px;
}
.tele-no a {
    text-decoration: none;
    color: #fff;
    transition: all .5s;
}

.tele-no a:hover {
    color: #06172f;
}
.contact-frm-col
{
    padding-top: 100px;
    padding-bottom: 100px;
}
#application-pg .contact-frm-col {
    padding-top: 50px !important;
}
.contact-frm-col form 
{
    width: 85%;
    margin: auto;
}
.cntct-ttl{
    
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 30px;
    letter-spacing: -0.015em;
    color: #06172F;
    line-height: 39px;
    text-align: center;
    margin-bottom: 40px;
}
.fld_dv
{
    width: 44%;
    margin-right: 4%;
    margin-bottom:0px;
    float: left;
    height: 75px;
}
.fld_dv1
{
    width: 96%;
    float: left;
    height: 125px;
}
.error_line
{
  border-bottom: 1px solid #ca2c1d !important;
}
.error_msg
{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    color: #c1352a;
    margin-top: 0;
    margin-bottom: 1rem;
    display: none;
}
.show_error
{
    display: block;
}
.hide_error
{
    display: none;
}
.contact-frm-col form .input-fld {
    border: none;
    border-bottom: 1px solid #cecece;
    width: 100%;
    margin-right: 4%;
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 18px;
    padding-bottom: 5px;
    margin-bottom: 4px;

}
.contact-frm-col form .txtarea
{
    border: none;
    border-bottom: 1px solid #cecece;
    width: 100%;
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 18px;
    padding-bottom: 5px;
    margin-bottom: 0px;
}
.contact-frm-col form .input-fld:focus , .txtarea:focus
{
    outline:none;
}
.contact-frm-col form .input-fld::placeholder , .contact-frm-col form .txtarea::placeholder
{
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 18px;
    padding-bottom: 5px;
    opacity:1;
    color:#000;
}
input.submit-btn.rd-btn {
    border: none;
    margin: auto;
    text-transform: uppercase;
    transition: background-color 0.6s;
}

#contact-pg #get-in-touch {
    display: block !important;
    position: relative !important;
    height: auto;
    width: 100%;
    z-index: 0;
}
#contact-pg .contact-frm-col {
    width: 100%;
    max-width: 100% !important;
    position: relative;
    transform: none;
    top: auto;
    left: auto;
    box-shadow: none;
}
.contact-frm-col form .srvc_dv {
    width: 96% !important;
}

@media screen and (min-width:320px) and (max-width:768px){
    .cont-col1 {
        padding: 40px 40px 20px 40px;
        height: auto;
    }
    .contact-frm-col form .input-fld {
        width: 100%;
        background: #fff;
    }   
    .contact-frm-col {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .fld_dv1
    {
        width: 100%;
    }
    .contact-frm-col form .srvc_dv {
        width: 100% !important;
    }
}

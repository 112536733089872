.funimg-slider
{
    padding-bottom: 200px;
}
.fun-sld {
    margin-top: 30px;
}
.fun-sld .slick-arrow:hover 
{
    background-color: #000;
    transition:0.3s all ease-in;
}
.fun-sld .slick-prev:hover:before
{
    background-image: url('../img/left-arrow.png');
}
.fun-sld .slick-next:hover:before
{
    background-image: url('../img/right-arrow.png');
}
.fun-sld .slick-prev {
    left: 520px;
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #000;
    border-radius: 50%;
    z-index: 1;
    top: auto;
    bottom: -130px;
}
.fun-sld .slick-prev:before
{
    content: '' ;
    background-image: url('../img/black-left-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;          
}
.fun-sld .slick-next {
    
    right: 520px;
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #000;
    border-radius: 50%;
    top: auto;
    bottom:-130px;
    z-index: 1;
}
.fun-sld .slick-next:before
{
    content: '' ;
    background-image: url('../img/black-right-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;
}
.fun-ttl
{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #06172F;
    margin-bottom: 14px;
    text-align: center;
}
.fun-sub-ttl
{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 18px;
    line-height: 32px;
    color: #06172F;
    text-align: center;
}
.fun-sld .item:focus-visible
{
    outline: none;
}


@media screen and (min-width:320px) and (max-width:768px) {
    .funimg-slider {
        padding: 50px 20px 30px 20px !important;
    }
    .funimg-slider .my-masonry-grid {
        display: flex;
        margin-left: 0px;
        width: auto;
    }
    
    .funimg-slider .my-masonry-grid_column {
        padding-left: 0px;
    }
    
    .funimg-slider .item img {
        width: -webkit-fill-available;
    }

    .funimg-slider .my-masonry-grid {       
        margin-left: -30px;
    }
    .funimg-slider .my-masonry-grid_column {
        padding-left: 30px;
    }
    .capr-lif .whyCp-col2 {
        height: auto;
        display: none!important;
    }
}

@media screen and (min-width:769px) and (max-width:1024px) {

    .funimg-slider {
        padding-bottom: 50px;
    }  

    .funimg-slider .my-masonry-grid_column {
        padding-left: 0px;
        margin-left: 30px;
    }
    
    .funimg-slider .my-masonry-grid{
        display: flex;
        margin-left: 0px;
        width: auto;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        align-items: center;
        padding: 0 65px;
    }
    
    .funimg-slider .fun-img {
        width: -webkit-fill-available;
    }
    .funimg-slider .my-masonry-grid_column:first-child {
        margin-left: 0px;
    }
    
 }


/*-------------------------------------------------------------------- Spotlight Css Start Here */
    
.spotlight-main
{
    height:100vh;
    position: relative;
}
.sld1::after {
    content: '';
    position: absolute;
    width: 12px;
    height: 100%;
    top: 0;
    left: -5px;
    background: #09162f;
}
.overlay-img
{
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    z-index: 1;
    /* background-color:#00000080; */
}
.overly-img
{
    width:100%;
    height:100%;
}
.spotlight-sld {
    height:100%;
}

.spotlight-sld .slick-prev {
    left: 80px;
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #fff;
    border-radius: 50%;
    z-index: 1;
    top: auto;
    bottom: 30px;
}
.spotlight-sld .slick-prev:before
{
    content: '' ;
    background-image: url('../img/left-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;          
}
.spotlight-sld .slick-next {
    
    left: 160px;
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #fff;
    border-radius: 50%;
    top: auto;
    bottom: 30px;
    z-index: 1;
}
.spotlight-sld .slick-next:before
{
    content: '' ;
    background-image: url('../img/right-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;
}
.spotlight-sld .slick-arrow:hover 
{
    background-color: #fff;
    transition:0.3s all ease-in;
}
.spotlight-sld .slick-prev:hover:before
{
    background-image: url('../img/black-left-arrow.png');
}
.spotlight-sld .slick-next:hover:before
{
    background-image: url('../img/black-right-arrow.png');
}
.slick-active.slick-current .sld-cnt
{
    animation-name: fadeInUpSD;
    animation-duration: 2s;
    opacity: 1;
}
@keyframes fadeInUpSD {

    0% {
        opacity: 0;
        -webkit-transform: translateY(100px);
        transform: translateY(100px);
    }
    
    100% {
        opacity: 1;
        -webkit-transform: none;
        transform: none;
    }
}
  
.fadeInUpSD {
    -webkit-animation-name: fadeInUpSD;
    animation-name: fadeInUpSD;
}
.spotlight-sld .slick-list, .spotlight-sld .slick-track, .spotlight-sld .slick-slide > div
{
    height: 100%;
}
.spotlight-sld .sld1
{
    /* background-image: url('../img/spot-img1.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.spotlight-sld .sld2
{
    background-image: url('../../assets/img/homepage/spotlight/capricorn_logistics_homepage_spotlight_two.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.spotlight-sld .sld3
{
    background-image: url('../../assets/img/homepage/spotlight/capricorn_logistics_homepage_spotlight.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}
.spotlight-sld .sld
{
    position: relative;
    height: 100%;
    display: flex !important;
    align-items: center;
    padding-left: 80px;
    background-color: #08172d;
}
.sld-cnt
{
    height:fit-content;
    width: 50%;
    color: #fff;
    z-index: 1;
}
.sld-cnt .spt-ttl
{
    font-family: 'Montserrat', sans-serif;
    /* font-size: 70px;
    line-height: 89px; */
    font-size: 3.7vw;
    line-height: 1.25em;
    font-weight: 700;
}
.sld-cnt .spt-dcrp
{
    font-family: 'Open Sans', sans-serif;
    font-weight: 300;
    font-size: 17px;
    line-height: 32px;
    width: 99%;
    margin-top: -10px;
}

.spt-btn
{
    float: left;
    margin-right: 30px;                                             
}

video {
    object-fit: cover;
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    left: 0;
  }

  @media screen and (max-width: 1024px){
    .sld1::after {
        background: none;
    }
  }


  @media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
    .sld-cnt {
        width: 58% !important;  
    }

  }
  
  @media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
    .sld-cnt {
        width: 58% !important;  
    }
  
  }



/*-------------------------------------------------------------------- Spotlight Css End Here */



.contact-frm-col form .input-fld {
    float: left;
}
.fld-dv {
    width: 50%;
    float: left;
    display: grid;
    margin-top: -22px;
}
.fld-dv label
{
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 18px;
    margin-bottom: 8px;
}
.dprt-drp
{
    margin-top: 11px;
}
.applicant-btn-dv
{
    float: left;
    width: 100%;
    margin-top: 30px;
}

#application-pg .contact-frm-col {
    width: 100%;
    max-width: 100% !important;
    position: relative;
    transform: none;
    top: auto;
    left: auto;
    box-shadow: none;
}

#application-pg #get-in-touch {
    display: block !important;
    position: relative !important;
    height: auto;
    width: 100%;
    padding: 10px 0px 20px 0px;
    z-index: 0;
}

.otp-fld {
    position: relative;
}


.fld_dv {
    position: relative;
}
.fld_dv1 label {
    display: block;
}
button.snd-otp-btn {
    position: absolute;
    top: 0;
    width: auto;
    left: auto;
    right: 0;
    font-size: 14px;
    border: none;
    text-decoration: underline;
    text-transform: uppercase;
    background-color: #fff0;
    color: #095792;
    font-weight: 600;
}
button.snd-otp-btn:focus-visible {
    outline: 0;
}

@media screen and (min-width:1024px) {

div#application-pg .srvc_dv {
    width: 46%!important;
}

div#application-pg  .fld_dv1 {
    width: 46%;
}
}

@media screen and (min-width:320px) and (max-width:768px) {

    button.snd-otp-btn {    
        top: 76px;
        right: 0;
        text-align: end;
    }
    .fld-dv {
        width: 90%;
    }
    
}

@media screen and (min-width: 1024px) and (max-width: 1279px) {
    .applcnt-pg-sptlt {
        padding-left: 20px;
        padding-right: 20px;
    }
}
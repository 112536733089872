body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* height:100vh;
  width:100vw; */

}

/* Font Options */
/* h1, h2, h3, h4, h5, h6, p.sec-ttl {
  font-family: 'Open Sans'!important;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
a:hover {
  color: inherit;
}
.gsap-marker-start , .gsap-marker-end , .gsap-marker-scroller-end , .gsap-marker-scroller-start
{
  display:none !important;
}
.txt-blue
{
  color: #095792 !important;
}
.light-blue {
  background-color: #095792;
  border-top: 1px solid hsl(0deg 0% 100% / 20%);
}
.cstm-container
{
  max-width:1190px;
  width:100%;
  margin: auto;
}
.pdng-tb
{
    padding-top:100px;
}
.pdng-b
{
  padding-bottom:100px;
}
.sec-pd-tb
{
  padding-top:100px;
  padding-bottom:100px;
}
.rd-btn
{
  width: 175px;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Montserrat';
  line-height: 20px;
  display: flex;
  background: #CA2D1D;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  border: 3px solid #ca2d1d;
  transition: all .6s;
}

.service-box {
  overflow: hidden;
}
img.servc_btn-arrow {
  transform: translateX(0px);
  transition: transform 1s;
}
.srvc-arrow-btn:hover img.servc_btn-arrow {
  transform: translateX(71px);
}

.rd-btn:hover {
  background-color: #fff;
  color: #ca2d1d;
  border: 3px solid #ca2d1d !important;
}

.srvc-arrow-btn:hover {
  background-color: #8f2015 !important;
  border: 3px solid #8f2015 !important;
}

.brder-btn
{
  width: 175px;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Montserrat';
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  border: 3px solid #FFFFFF;
  transition: background-color .5s;
}

.brder-btn:hover {
  background-color: #ca2d1d;
  color: #fff;
}

.pdf-btn
{
  width: 240px;
  height: 50px;
  font-weight: 600;
  font-size: 16px;
  font-family: 'Montserrat';
  line-height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  text-decoration: none;
  border: 3px solid #FFFFFF;
  transition: background-color .5s;
}
.pdf-btn:hover
{
  background-color: #ca2d1d;
  color: #fff;
}
.icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.icon.icon--left {
  position: absolute;
  top: 0;
  left: -71px;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.global-btn {
  width: 230px;
}

h2.apply-nw-txt {
  max-width: 1190px;
  width: 100%;
  margin: auto;
  text-align: center;
  padding-top: 60px;
}


@-webkit-keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}
@keyframes fadeIn {
  from { opacity: 0; }
    to { opacity: 1; }
}

.pg-layout {
  overflow: hidden;
}


html {
  scroll-padding-top: 80px;
}

/* :root {
  scroll-behavior: auto;
} */

.image_popup {

}

.img_pop_row{
  height: 100vh;
  width: 100%;
  z-index: 999;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  overflow-x: none;
  -webkit-overflow-scrolling: touch;
  transform: none;
  max-width: none;
  pointer-events: all;
  padding: 10px;
  display: block;
  background-color: #0000005c;
  opacity: 1;
  transition:0.5s all;
}
.img_pop_row.hidden {
  opacity:0;
  display: none;
}
.img_pop_col {
  padding-top: 70px;
  padding-bottom: 30px;
  width: 100%;
  max-width: 500px;
  margin: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0px 0px 4px 0px;
}

.img_pop_col img {
    width: 100%;
}
@media screen and (min-width:1024px) {
  .jrny-col1 h2, .srvc-ttl  {
    font-size: 4.1vw !important;
  }
}

@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {

  .img_pop_col {
    padding: 50px 5px 5px 0px;
    width: 90%;
  }
  button.img_pp_cls {
    top: 15px !important;
    right: 0px !important;
    font-size: 55px !important;
  }
  .pdf-btn {
      width: 215px !important;
  }
  
}


@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {

  .img_pop_col {
    padding: 50px 5px 5px 0px;
    width: 90%;
  }
  button.img_pp_cls {
    top: 15px !important;
    right: 0px !important;
    font-size: 55px !important;
  }

  .pdf-btn {
    width: 290px !important;
    margin-top: 20px;
  }


}



@media only screen and (min-device-width: 320px) and (max-device-width: 767px) {

  .img_pop_col {
    padding: 40px 5px 5px 0px;
    width: 90%;
  }
  button.img_pp_cls {
    top: 5px !important;
    right: 0px !important;
    font-size: 35px !important;
  }
  .pdf-btn {
    width: 100% !important;
  }


}



.ads_pop_up_hide{
  display: none;
}



.btmbr {
    background-color: #000;
    padding: 10px;
    display: grid;
    width: 100%;
}

.btmbr .ps-bnr {
    max-width: 1190px;
    width: 100%;
    margin: auto;
}

.btmbr .ps-bnr p {
    color: #c3c3c3;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.015em;
    text-align: right;
    margin: 0;
}

.btmbr .ps-bnr p a {
    color: #c3c3c3;    
    text-decoration: none;
}

.btmbr .ps-bnr p a:hover {
    /* color: #ca2d1d; */
    text-decoration: underline;
}

.footer-main {
    width: 100%;
    float: left;
    height: 620px;
}
.blue-col
{
    background: #06172F;
    height: 100%;
    width: 55.5%;
    float:left;
    
}
.blue-col > .row
{
    margin: 0px;
}
.blue-prt-1
{
    padding: 80px 30px 50px 80px;
    border-bottom: 0.5px solid rgb(255 255 255 / 20%);
}
.blue-prt-2
{
    padding: 45px 30px 15px 80px;
    border-bottom: 0.5px solid rgb(255 255 255 / 20%);
}
.blue-prt-3 {
    padding: 0px 0px 0px 80px;
    display: grid;
    height: 58px;
    align-items: center;   
}
h4.menu-ttl {
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.2px;
    text-transform: uppercase;
    color: #FFFFFF;
    margin-bottom: 15px;
}
ul.service-list , ul.menu-list{
    padding: 0;
    list-style: none;
}
.service-list li, .menu-list li {
    margin-bottom: 10px;
}
.f-menu-link
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    margin-bottom: 20px;
    text-decoration: none;
    transition: all 0.5s;
}
.f-menu-link:hover {
    color: #ca2d1d;
}
.cpy-txt , .cpy-link
{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    opacity: 0.2;
    text-decoration: none;
    margin-bottom: 0px;
}
.cpy-link
{
    margin-right: 25px;
}
a.cpy-link:hover {
    color: #ca2d1d;
    opacity: 1;
}
.red-col
{
    background: #CA2D1D;
    height: 100%;
    width: 44.5%;
    float:left;
    position: relative;
    padding: 85px 0px 0px 60px;
    overflow: hidden;
}
.rd-cl-ttl
{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 50px;
    line-height: 54px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    margin-bottom: 30px;
}
.footer_bg
{
    float: right;
    width: 91%;
}
.red-col form .newslttr-email {
    width: 394px;
    height: 70px;
    border: none;
    padding-left: 24px;
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 16px;
    line-height: 28.8px;
    letter-spacing: -0.015em;
    color: #06172F;
    border: 3.5px solid rgb(202 45 29 / 70%);
}
.red-col form .newslttr-email::placeholder{
    font-family: 'Montserrat';
    font-weight: 300;
    font-size: 16px;
    line-height: 28.8px;
    letter-spacing: -0.015em;
    color: #06172F !important;
    opacity: 1 !important;
}
.red-col form .newslttr-email:focus
{
    outline: none;
}
.red-col form .newslttr-btn
{
    width: 106px;
    height: 50px;
    background: #06172F;
    border: none;
    color: #fff;
    margin-left: -115px;
    letter-spacing: 0.2px;
    font-family: 'Montserrat';
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    transition: all .5s;
    display: inline;
    border-radius: 0;
    position: relative;
    margin-top: 10px;
}
.subscribe .fld_dv {   
    width: auto; 
    margin-right: 0;
    float: left;
}

.subscribe .error_msg {
    color: #ffffff;
}

.red-col .thnk_show {
    color: #ffff;
    text-align: left;
    margin: 0;
}

.red-col form .newslttr-btn:hover {
    background: #CA2D1D;
    color: #fff;
}
.social-list
{
    padding: 0;
    list-style: none;
    margin-top: 30px;
}
.footer-para {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 13px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    opacity: 0.3;
    line-height: 25.6px;
    width: 87%;
    margin-left: 45px;

}
.social-list li
{
    float: left;
    margin-right: 14px;
}
a.social-circle {
    width: 34px;
    height: 34px;
    background-color: #fff;
    display: block;
    text-align: center;
    border-radius: 50%;
    padding-top: 3px;
    transition: all .5s;
}
a.social-circle:hover {
    background-color: #ca2d1d;
    color: #fff;
}

@media only screen and (min-device-width: 1910px) and (max-device-width: 1920px) {
  
    .footer_bg {
        width: 63%;
    }
    .blue-prt-3 {
        height: 92px;
    }

}
@media only screen and (min-device-width: 1590px) and (max-device-width: 1600px) {
  
    .footer_bg {
        width: 76%;
    }
    .blue-prt-3 {
        height: 80px;
    }


}
@media only screen and (min-device-width: 1430px) and (max-device-width: 1440px) {
  
    .footer_bg {
        width: 85%;
    }

}
@media only screen and (min-device-width: 1270px) and (max-device-width: 1280px) {
  
    .footer_bg {
        width: 97%;
    }
    .blue-prt-1 , .blue-prt-2 , .blue-prt-3
    {
        padding-left:40px;
    }
}


@media screen and (min-width:1024px) {
    #mob-subs {
        display: none;
    }
}

@media screen and (min-width:320px) and (max-width:768px){
    #desk-subs {
        display: none;
    }
    .footer-main {
        float: none;
        height: auto;
    }
    .footer_bg {
        display: none;
    }
    .red-col {
        width: 100%;
        float: left;
        padding: 40px 20px 55px 20px;
    }
    .rd-cl-ttl {
        font-size: 36px;
        text-align: center;
        line-height: 48px;
    }
    .red-col form .newslttr-email {
        width: 100%;
        padding-left: 10px;
    }
    .blue-col {
        width: 100%;
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 40px;
        text-align: center;
    }
    .blue-prt-1 {
        padding: 0;
        border: none;
    }
    ul.social-list {
        text-align: center;
        display: inline-flex;
    }
    .footer-para {
        font-size: 14px;
        line-height: 25.6px;
        width: 320px;
        margin: auto;
        padding-top: 15px;
    }
    .blue-prt-2 {
        padding: 25px 0px 0px 0px;
    }
    .blue-prt-3 {
        padding: 15px 0px 25px 0px;
        height: auto;
        align-items: center;
    }
    .cpy-link {
        display: block;
        margin-right: 0px;
    }
    h4.menu-ttl {
        font-size: 12px;
    }
    p.cpy-txt {
        margin-bottom: 10px;
    }
    a.cpy-link {
        margin-bottom: 10px;
    }
    img.footer_logo {
        display: block;
        text-align: center;
        margin: auto;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
.global_main .global-btn {
    width: 25%;
    margin: auto;
}
.footer-para {    
    width: auto;
    margin: auto;
}
}

@media only screen and (min-width:1024px) and (max-width:1279px) {
    .blue-prt-1 {
        padding: 80px 30px 50px 20px;
    }
    .red-col {
        padding: 85px 0px 0px 30px;
    }
    .blue-prt-2 {
        padding: 45px 30px 0px 30px;
    }
    .footer-main {
        height: auto;
        overflow-x: hidden;
    }
    .red-col {
        padding: 85px 0px 115px 30px;
    }
    .blue-prt-3 {
        padding: 0px 0px 25px 30px;
        width: 200%;
        max-width: 200%;
        z-index: 999;
        background-color: #06172f;
        padding-bottom: 15px;
    }
    img.footer_logo {
        width: 165px;
    }
}
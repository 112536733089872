div#connect-us {
    padding-top: 125px;
    padding-bottom: 114px;
}

h2.connect-ttl {
    font-family: Montserrat;
    font-size: 70px;
    font-weight: 700;
    line-height: 71px;
    letter-spacing: -0.015em;
    text-align: left;
}

p.connect-para {
    font-family: Montserrat;
    font-size: 22px;
    font-weight: 500;
    line-height: 29px;
    letter-spacing: -0.015em;
    text-align: left;
}

a.connect-us-btn {
    width: auto;
    height: 50px;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Montserrat';
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ca2d1d;
    text-decoration: none;
    border: 3px solid #ca2d1d;
    text-align: center;
    padding: 20px 54px;
    transition: all 0.5s;
}

a.connect-us-btn:hover {
    background-color: #ca2d1d;
    border-color: #ca2d1d;
    color: #fff;
}

.connect-col2 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: flex-end;
    align-items: center;
}

div#connect-us .cstm-container {    
    width: 90%;
    padding-left: 15px;
    padding-right: 15px;
}
a.connect-us-btn:first-child {
    margin-right: 35px;
}

.red-btn {
    width: auto;
    height: 50px;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Montserrat';
    line-height: 20px;
    display: flex;
    background: #CA2D1D;
    align-items: center;
    justify-content: center;
    color: #fff !important;
    text-decoration: none;
    border: 3px solid #ca2d1d !important;
    transition: all .6s;
}


.red-btn:hover {
    background-color: #fff !important;
    color: #ca2d1d !important;
    border: 3px solid #ca2d1d !important;
}

@media screen and (min-width:320px) and (max-width:767px) {
    h2.connect-ttl { 
        font-size: 36px;
        line-height: 48px;
        text-align: center;
    }
    a.connect-us-btn:first-child {
        margin-right: 0;
        margin-top: 35px;
    }
    a.connect-us-btn {
        margin-bottom: 25px;
    }
    div#connect-us {
        padding-top: 60px;
        padding-bottom: 55px;
    }
    .connect-col2 {        
        justify-content: center;
        flex-direction: column;
    }
    p.connect-para {
        text-align: center;
    }
}

@media screen and (min-width:768px) and (max-width:1023px) {
    .connect-col2 {
        justify-content: flex-start;
    }
}

@media screen and (min-width:1024px) and (max-width:1279px) {
    h2.connect-ttl {
        font-size: 50px;
    }
    .connect-col2 {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        align-items: flex-start;
        justify-content: space-evenly;
    }
    .connect-col1 {
        padding-right: 55px;
    }
}
/*-------------------------------------------------------------------- Services Css Start Here */

.service_main
{
    background: #06172F;
    width: 100%;
}
.srvc-sub-ttl
{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    letter-spacing: -0.015em;
    color: #095792;
}
.top-spac
{
    padding-top: 50px;
}
.srvc-ttl
{
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 70px;
    line-height: 71px;
    color: #FFFFFF;
    letter-spacing: -0.015em;
}
.service-box 
{
    height:auto;
    background: #fff;
}
.service-box img.servc_img
{
    width: 100%;
    height: 44.3%;
    object-fit: cover;
}
.srvc_contact
{
    background-color: #fff;
    padding: 30px 45px 30px 30px;
    /* height: 55.6%; */
    height: auto;
    position: relative;
    color: #BDC0CA;
}
.srvc-bx-ttl
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 22px;
    line-height: 28.6px;
    color: #000000;
    letter-spacing: -0.015em;
    margin-bottom: 13px;

}
.srvc-bx-para
{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.015em;
    margin-bottom: 10px;
    color: #212529;
}
.srvc-arrow-btn
{
    width: 58px;
    height: 58px;
    position: absolute;
    top: auto;
    bottom: 0;
    right: 0;
    overflow: hidden;
}
.cstm-hide
{
    visibility: hidden;
}
.custm-brokg
{
    margin-top: 70px;
    padding-right: 30px;
}
.gloabl-fght
{
    margin-top: -60px;
    padding-right: 30px;
}
.sply-chn
{
    margin-top: -60px;
    padding-right: 30px;
}
.vlu-add
{
    margin-top: -295px;
    padding-right: 30px;
}
.indstry-factry
{
    margin-top: -180px;
    padding-right: 30px;    
    z-index: 1;
}
.servc_bg
{
    position: absolute;
    top: auto;
    bottom: 107px;
    width: 56.4%;
}
.srvc-rw
{
    position:relative;
}
.industrl .vlu-add {
    margin-top: -380px !important;
}
.servc-lang-pg {
    padding-top: 250px;
}
/*------Services Css Start Here new design stylings ----------*/
ul.serv_cap_icn_lst {
    list-style-image: url(../img/Boomerrang_services.png);
    list-style-type: none;
    padding-left: 22px;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #fff;

    
}

ul.serv_cap_icn_lst li {
    padding: 0 0 25px;
}

h5.serv_sub-hd_ttl {
    color: #CA2D1D;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 700;
    padding-top: 20px;
}

.serv_ttl_new {
    padding-top: 70px;
}

.serv_img_overlap {
    z-index: 1;

}

.new_serv_extra_spc {
    padding-bottom: 400px;
}

/*-------------------------------------------------------------------- Services Css Start Here */

.vlu-spacmange {
    padding-top: 0;
    margin-top: -295px;
    padding-left: 12px;
}

#home-pg-grid .vlu-spacmange {
    margin-top: -273px;
}

.sply-spacmange {
    padding-left: 12px;
}
.service-box a.sub-servc-lnk {
    font-family: Montserrat;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: 0.2px;
    text-decoration: none;
    color: #095792;
}
.service-box a.sub-servc-lnk:hover {
    color: #ca2d1d;
}
div#industrial-pg-grid .srvc_contact {
    padding: 30px 45px 45px 30px;
}
div#industrial-pg-grid .indstry-factry {
    margin-top: -210px;
}

#home-pg-grid .indstry-factry {
    margin-top: -157px;
}

div#industrial-pg-grid .vlu-add {
    margin-top: -350px !important;
}
div#home-pg-grid .srvc_contact {
    padding: 30px 45px 30px 30px;
}

div#home-pg-grid {
    padding-bottom: 50px;
}

@media screen and (min-width:320px) and (max-width:768px){
    .servc-lang-pg {
        padding-top: 150px !important;
    }
    .industrl .vlu-add, div#industrial-pg-grid .vlu-add {
        margin-top: 48px !important;
    }
    .srvc-bx-ttl {
        text-align:center;
    }
    ul.serv_cap_icn_lst {
        text-align: center;
        list-style-position: inside;
        padding-left: 10px !important;
        padding-right: 10px;
    }
    ul.serv_cap_icn_lst li {
        width: fit-content;
        margin: auto;
    }
    p.srvc-bx-para {
        text-align: center;
    }
    .srvc_contact {
        padding: 30px;
    }
}


@media only screen and (min-width:1024px) and (max-width:1279px) {
    .sply-chn .srvc_contact br {
        display: none;
    }
}


@media only screen and (min-width:600px) and (max-width:1279px) {
    .srvc_contact:nth-child(2) br {
        display: none;
    }
}
.about-sec1-main .abt-col1 {
    padding-right: 0px;
}
.about-sec1-main .dot {
    width: 12px;
    height: 12px;
    background: #06172F;
    margin-right: 15px;
    float: left;
    border-radius: 50%;
    margin-top: 2px;
}
.about-sec1-main .sec-name-ttl {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.015em;
    color: #06172F;
    margin-bottom: 15px;
}
.about-sec1-main .sec-ttl {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 30px;
    letter-spacing: -0.015em;
    color: #06172F;
    line-height: 39px;
}
.about-sec1-main .dcrb {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.015em;
    color: #06172F;
    line-height: 29px;
}

.about-sec1-main .abt-col2 {
    padding-left: 60px;
}
.roll_txt_dv
{
    overflow: hidden;
    padding:100px 0px;
    /* padding-bottom: 0px; */
}
h2.rollingText {
    font-family: 'Open Sans';
    font-size: 95px;
    font-weight: 800;
    white-space: nowrap;
    -webkit-text-stroke: 1px #000;
    color: white;
    transform: translateX(1000px)
}

p.sec-ttl-2 {
    font-family: Montserrat;
    font-size: 18px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: -0.015em;
    text-align: left;
}

.mob_roll_txt_dv {
    display: none;
}

.about-sld .slick-dots li button {
    position: absolute;
        margin: 0;
        left: -5px;
        top: -5px;
    }

@media screen and (min-width:320px) and (max-width:768px){
    #about-pag .about-sec1-main {
        padding-top: 50px;
    }
    
    .about-sec1-main {
        padding-right: 15px !important;
        padding-left: 15px !important;
        text-align: center !important;
    }
    .about-sec1-main .sec-ttl-2 {    
        font-size: 14px;
        text-align: center;
    }
    .about-sec1-main .abt-col2 {
        margin-top: 0px;
    }
    .mob_roll_txt_dv {
        /* overflow: hidden; */
        display: block;
        padding: 30px 0px;
    }
    .mob_rollingText {
        font-family: 'Open Sans';
        font-size: 40px;
        font-weight: 700;
        white-space: nowrap;
        -webkit-text-stroke: 1px #000;
        color: white;
        transform: translateX(500px);
    }
    .desk_txt {
        display: none;
    }
    .mob_roll_txt_dv h2.rollingText {
        font-size: 40px;
    }

}
@media screen and (min-width:1024px) and (max-width:1279px){
    .about-sec1-main {
        padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px;
    }
}
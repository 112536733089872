.service_spotlight
{
    padding-top:180px;
    padding-bottom: 0px;
    position: relative;
    /* height:100vh; */
}
.sptlgt_img_algn {
    text-align: right;
    margin-bottom: 0;
}

.srvc_spotlight_img
{
    position: relative;
    top: auto;
    bottom: 0;
    left: auto;
    right: 0;
     max-width: 90%;
}
.service_spotlight .sec-name-ttl
{
    font-family:'Montserrat';
    font-weight:600;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 1px;
    color: #06172F;
    text-transform: uppercase;
}
.service_spotlight .sec-name-ttl span
{
    color: #CA2D1D;   
}
.service_spotlight .sec-ttl
{
    font-family:'Montserrat';
    font-weight:bold;
    font-size: 70px;
    line-height: 70px;
    color: #06172F;
}
.service_spotlight .sec-sub-ttl
{
    font-family:'Montserrat';
    font-weight:500;
    font-size: 30px;
    line-height: 39px;
    color: #000;
}

@media only screen and (min-device-width: 1360px) and (max-device-width: 1366px) {
    .service_spotlight
    {
        padding-top:125px;  
    }
}

.ntwrk-sptlt, .applcnt-pg-sptlt {
    height: auto;
}
.ntwrk-sptlt {
    padding-bottom: 100px;
}

.applcnt-pg-sptlt {
    padding-bottom: 0px;
}

@media screen and (min-width:320px) and (max-width:768px) {
    
    .service_spotlight {
        padding-left: 15px;
        padding-right: 15px;
    }
    .service_spotlight .sec-name-ttl {
        text-align: center;
    }
    .service_spotlight .sec-ttl {
        font-size: 36px !important;
        line-height: 48px !important;
    }
    .service_spotlight .sec-sub-ttl {
        font-size: 22px;
        line-height: 28px;
    }
    .service_spotlight .abt-col2 {
        margin-top: 5px;
    }
    .air-frght-col1 {
        padding-left: 15px;
        padding-right: 15px;
        padding-top: 50px;
        height: auto;
    }
    .air-frght-col1 {
        align-items: flex-start;
        padding-top: 50px;
    }
    h2.air-frgt-ttl {
        font-size: 30px;
        line-height: 39px;
        text-align: center;
    }
    p.air-frgt-para {
        font-size: 18px;
        line-height: 23px;
        text-align: center;
    }
    .mbl-img {
        display: block !important;
    }
    .srvc_spotlight_img {
        display: none;
    }
    .service_spotlight {
        padding-bottom: 30px;
    }
    .service_spotlight
    {
    padding-top:150px;
    }
    #srvcs-ttl br {
        display: none;
    }
}

@media screen and (min-width:1024px) and (max-width:1279px) {
    
    .service_spotlight {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.sec-name-ttl a {
    text-decoration: none;
    color: #06172F;
}
.sec-name-ttl a:hover {
    color: #ca2d1d !important;
}

@media screen and (min-width:1025px){

    .fmcg-col .sec-ttl br {
        display: none;
    }
}


.air-frght-col1
{
    background-color: #095792;
    display: flex;
    align-items: center;
}

.air-frgt-ttl
{
    font-family: 'Montserrat';
    font-weight:bold;
    font-size: 70px;
    line-height:71px;
    color: #fff;
    margin-bottom: 30px;
}
.air-frgt-para
{
    font-family: 'Montserrat';
    font-weight:300;
    font-size: 22px;
    line-height:29px;
    color: #fff;
}
.air-frght-col2 {
    background-color: #06172f;
}

.mbl-img {
    display: none;
}
@media screen and (min-width:1024px) and (max-width:1279px){
    #warehousing .air-frght-col1 {
        padding: 100px 100px 50px 35px !important;
    }
    .cross-drock-spacmange {
        margin-top: -18px;
    }
    .cross-drock-spacmange {
        margin-top: -20px;
    }
    div#transportation .ocen-frght-col2 {
        padding: 50px;
    }
    #transportation .hd-dv {
        width: 95%;
    }
    .value_added_services .repacking-spacmange {
        margin-top: -175px;
    }
    .trnsprt-txt {
        font-size: 60px;
    }
}

@media screen and (min-width:320px) and (max-width:768px) {
    
    .air-frght-col1 {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 50px;
        height: auto;
        align-items: flex-start;
        padding-top: 50px;
    }
    
    h2.air-frgt-ttl {
        font-size: 30px;
        line-height: 39px;
        text-align: center;
    }
    p.air-frgt-para {
        font-size: 18px;
        line-height: 23px;
        text-align: center;
    }
    /* .mbl-img {
        display: block;
    } */
    .mbl-img img {
        width: 100%;
        height: auto;
    }
    .desk-img {
        display: none!important;
    }
    .value_added .air-frght-col1 {
        justify-content: center;
    }
    .air-frght-col1 {
        justify-content: center;
    }
    .automotive h2.air-frgt-ttl, .engineering h2.air-frgt-ttl, .fmcg h2.air-frgt-ttl, .pharma .air-frgt-ttl, .telecom h2.air-frgt-ttl  {
        margin-bottom: 0px;
    }
    
}

@media screen and (min-width:768px) and (max-width:1023px){
    
}
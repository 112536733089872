.inside_spotlight-main {
    height: 100vh;
    position: relative;
}
.inside_spotlight-main .about-dv {
    position: relative;
    height: 100%;
    display: flex !important;
    align-items: center;
    padding-left: 80px;
    background-image: url('../../assets/img/about-us/spotlight/capricorn_logistics_about_us_spotlight.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 1% -57%;
}
.inside_spotlight-main .overlay-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    /* background-color: #00000080; */
}
.inside_spotlight-main .overly-img {
    width: 100%;
    height: 100%;
}
.spot-inner-dv
{
    z-index: 1;
    height: 100%;
    display: flex !important;
    align-items: center;
    padding-top: 91px;
}
.inside_spotlight-main .spot-cnt {
    height: -moz-fit-content;
    height: fit-content;
    width: 50%;
    color: #fff;
    z-index: 1;
}
.contact-spot-cnt
{
    width: 100% !important;
}
.inside_spotlight-main .spot-cnt .spot-ttl {
    font-family: 'Montserrat', sans-serif;
    /* font-size: 70px;
    line-height: 75px; */
    font-size: 3.7vw;
    line-height: 1.05em;
    font-weight: 700;
    margin-bottom: 20px;
}

.inside_spotlight-main .spot-btn {
    float: left;
    margin-right: 30px;
}

@media screen and (min-width: 768px) and (max-width: 1024px){
.inside_spotlight-main .spot-cnt {
    width: 70%;
}}

@media screen and (min-width:320px) and (max-width:768px){
    .inside_spotlight-main .about-dv {
        padding-left: 0px;     
        text-align: center;
    }
    .inside_spotlight-main .spot-inner-dv {
        width: 85%;
        margin: auto;
    }
    .inside_spotlight-main .spot-cnt {
        width: 100%;
        margin-right: 0px;
        width: 100%;
        margin-bottom: 20px;
    }
    .inside_spotlight-main .spot-cnt .spot-ttl {
        font-size: 36px;
        line-height: 48px;
    }
    .inside_spotlight-main .spot-btn {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
    }
    .inside_spotlight-main {
        z-index: 0;
    }

}

@media screen and (min-width:768px) and (max-width:1023px){
    .inside_spotlight-main .spot-btn {
        width: 45%;
        margin-right: 25px;
        margin-bottom: 0px;
    }
}

@media only screen and (min-device-width: 1200px) and (max-device-width: 1280px) {
    .inside_spotlight-main .spot-cnt {
        width: 58% !important;  
    }

  }
  
  @media only screen and (min-device-width: 1281px) and (max-device-width: 1369px) {
    .inside_spotlight-main .spot-cnt {
        width: 58% !important;  
    }
  
  }



.team-main
{

}
.team-bx
{
    margin-top: 30px;
}
.tm-img-bx
{
    background-color:#E6CAA4;
}
.nw-bg
{
    background-color:#C9DBCC;
}
.tm-img-bx img
{

}

.team-content
{
    margin-top: 35px;
}
.tm-nm
{
    font-family: 'Montserrat';
    font-weight:500;
    font-size: 22px;
    line-height: 29px;

}
.tm-des
{
    font-family: 'Open Sans';
    font-weight:400;
    font-size: 16px;
    line-height: 29px;
    margin-bottom: 0px;
}
.bio-btn
{
    font-family: 'Open Sans';
    font-weight:400;
    font-size: 16px;
    line-height: 29px;
    color:#CA2D1D;
    text-decoration: none;
}
.team-main.sec-pd-tb {
    display: none;
}
.ocen-frght-col1
{
    background-color: #EFEDEE;
}
.ocen-frght-col2
{
    background-color: #CA2D1D;
    display: flex;
    align-items: center;
    padding-left: 80px;
}

@media screen and (min-width:320px) and (max-width:768px){
    .vlue-col2 .vlue_img {
        width: 100%;
        height: 332px;
        object-fit: cover;
    }
    .vlue-col2 {
        height: auto;
        padding-bottom: 0;
    }
    .ocen-frght-col2 {
        padding: 40px 20px;
        height: auto;
    }
}
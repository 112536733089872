.test-ttl
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    letter-spacing: -0.015em;
    color: #06172F;
    text-align: center;
    margin-bottom: 50px;
}

.testi-sld
{
    padding-left: 40px;
    margin-top: 30px;
}
.testi-sld .slick-arrow:hover 
{
    background-color: #000;
    transition:0.3s all ease-in;
}
.testi-sld .slick-prev:hover:before
{
    background-image: url('../img/left-arrow.png');
}
.testi-sld .slick-next:hover:before
{
    background-image: url('../img/right-arrow.png');
}
.testi-sld .slick-prev {
    left: -30px;
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #000;
    border-radius: 50%;
    z-index: 1;
    top: 90px;
}
.testi-sld .slick-prev:before
{
    content: '' ;
    background-image: url('../img/black-left-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;          
}
.testi-sld .slick-next {
    
    right: 0px;
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #000;
    border-radius: 50%;
    top: 90px;
    z-index: 1;
}
.testi-sld .slick-next:before
{
    content: '' ;
    background-image: url('../img/black-right-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;
}

.qut-img
{
    padding-left: 40px;
}
.testi-para
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 34px;
    letter-spacing: -0.015em;
    color: #06172F;
    width: 90%; 
}
.cust-dtl
{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: -0.015em;
    color: #06172F;
    margin-bottom: 0px;
}

.cust-dt2
{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: -0.015em;
    color: #c13529;
    margin-bottom: 0px;
}
@media screen and (min-width: 1025px){ 
    #mobil-img {
        display: none;
    }
}

@media screen and (min-width:320px) and (max-width:1024px){
    #desk-img {
        display: none;
    }
}
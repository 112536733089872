.navbar .megamenu{ padding: 1rem; }

.topbar_show
{
    height:27px;
    opacity:1;
    transition:all 0.4s;
    z-index: 0;
}
.topbar_hide
{
    height:0px;
    opacity:0;
    margin-bottom: 0px !important; 
    transition:all 0.4s;
    z-index: -1;
}
/* ============ desktop view ============ */
@media all and (min-width: 992px) {
	
	.navbar .has-megamenu{position:static!important;}
	.navbar .megamenu{left:0; right:0; width:100% !important; margin-top:0;  }
	
}	

/* ============ mobile view ============ */
@media(max-width: 991px){
	.navbar.fixed-top .navbar-collapse, .navbar.sticky-top .navbar-collapse{
		overflow-y: auto;
	    max-height: 90vh;
	    margin-top:10px;
	}
}

li.drp_menu .active a {
    color: #ca2d1d !important;
}

.srvc-insd3 .active h3, .srvc-insd4 .active h3, .srvc-insd5 .active h3 {
    color: #ca2d1d;
}


.tab_menu:hover {
    color: #CA2D1D !important;
}
.mega-menu-link:hover h3 {
    color: #CA2D1D !important;
}
.drp_icon
{
    margin-top: -5px;
    margin-left: 5px;
}
.custm_header {
    position:fixed;
    width:100%;
    z-index:2;
    padding: 0px;
    height: 91px;
    transition: height 0.5s;
    background-color: #06172F !important;
}
.custm_header > .container-fluid {
    padding: 0px;
}
.custm_header .logo {
    /* background-color: #fff; */
    padding: 23px 30px;
}
.dropdown-toggle::after {
    display: none;
}

.top-bar {
    width: 100%;
    float: left;
    padding-right: 30px;
    margin-bottom: 15px;
}

.scroll-menu{
    height: 50px !important;
}


.mail-dv {
    width: fit-content;
    float: right;
}
.phone-dv {
    width: fit-content;
    margin-left: 28px;
    float: right;
}
.phone-dv img, .mail-dv img {
    margin-right: 5px;
}
.search-dv {
    width: fit-content;
    float: right;
    margin-left: 28px;
}
.lang-dv {
    background: #173145;
    width: 60px;
    height: 25px;
    float: right;
    border-radius: 80px;
    margin-left: 28px;
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
}
.lang-dv a {
    color: #fff;
    text-decoration: none;
    /* padding-left: 15px; */
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}
.lang-dv .dropdown-item {
    background-color: transparent;
    color: #06172f;
    font-size: 11px;
    font-weight: 400;
}
.lang-dv .dropdown-item img {
    margin-right: 10px;
    margin-top: -2px;
}
.lang-menu {
    min-width: auto;
    top: 25px;
    left: 0;
}
.lang-icon {
    vertical-align: inherit;
}
a.txt {
    font-size: 13px;
    font-weight: 400;
    color: rgb(255 255 255 / 60%);
    text-decoration: none;
    padding-left: 5px;
    font-family: 'Montserrat', sans-serif;
    transition: all .5s;
} 
a.txt:hover {
    color: #ca2d1d;
}  
#main_nav li.nav-item {
    margin-right: 30px;
    cursor: pointer;
}
#main_nav ul li a.main-menu-lnk {
    color: #fff;
    padding: 0px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
}
.track_div {
    font-weight: 600;
    width: 193px;
    height: 91px;
    text-decoration: none;
    border-left: 0.5px solid rgba(255, 255, 255, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Montserrat', sans-serif;
    font-size: 17px;
    background-color: #06172f;
    color: #fff;
    transition: all .5s;
}
.track_div:hover {
    color: #fff;
    cursor: pointer;
    background-color: #c13529;
}
.megamenu {
    padding: 0 !important;
    border-radius: 0px;
    border-top: 14px solid #06172f00;
}
.megamenu .row {
    margin: 0;
}
.mega-cols {
    margin: 0;
    padding: 40px 30px 40px 40px;
}
.mdl-mega-col {
   border-left:1px solid #0000001a; 
   border-right:1px solid #0000001a; 
}
.mega-cols > div > h2 {
    font-size: 21px;
    font-family: 'Montserrat', sans-serif;
}
.pg-ttl {

    position:relative;
    text-align: center;
    width: 90%;
} 
.pg-ttl::before {

    content: '';
    position: absolute;
    width: 100px;
    height: 1px;
    background-color: #06172F;
    top: 15px;
    left: 0;
}
.mega-cols > div > P {
    margin-top: 40px;
    font-size: 17px;
    line-height: 30px;
    font-family: 'Montserrat', sans-serif;
}
.mega_nav_tabs {
    display: block;
    border:none;
    margin-bottom: 30px;
}
.mega_nav_tabs li{
    margin-bottom: 10px;
}
a.tab_menu {
    color: #06172F!important;
    text-decoration: none;
    font-weight: 500;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
}
a.tab_menu.active {
    color: #CA2D1D !important;
}
.mega-menu-link {
    display: flex;
    height: 83px;
    width: 100%;
    align-items: center;
    margin-bottom: 35px;
    text-decoration: none;
}
a.mega-menu-link:last-child {
    margin-bottom: 0px;
}

.mega-menu-link img {
    width:83px;
    float: left;
}
.mega-menu-link h3 {
    font-family: 'Montserrat', sans-serif;
    padding-left: 20px;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #06172F;
}

.srch-box
{
    position: relative;
    cursor: pointer;
}
.srch-box a.txt {
    padding-left: 12px;
}
.srch-box::before
{

    content: '';
    position: absolute;
    top: 1px;
    left: 0px;
    width: 25px;
    height: 25px;
    background-color: #173145;
    border-radius: 50%;
    z-index: -1;
    transition: all 0.5s;
}
.srch-box::after {
    content: '';
    position: absolute;
    width: 0px;
    height: 25px;
    top: 1px;
    background: #173145;
    left: 15px;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    z-index: -2;
    transition: all 0.5s;
}
.srch-box:hover::after
{
    width: 78px;
    background-color: #CA2D1D;
}
.srch-box:hover::before
{
    background-color: #CA2D1D;
}
.search_div
{
    position:fixed;
    top:0px;
    left:0px;
    width:100%;
    height:100vh;
    z-index: -2;
    background-color: rgb(0 0 0 / 85%);
    opacity:0;
    transition: all 1s;
}
.srch-box img {
    padding-left: 8px;
}
.srch-box .txt:hover {
    color: rgb(255 255 255 / 60%);
}
.search_div > .srch-row {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    width: 60%;
}
.search_div .srch-row .col-12 {
    width: 100%;
    background-color: #fff;
    padding: 40px 35px;
}
input.srch-input {
    border: 0px;
    border-bottom: 1px solid #CA2D1D;
    width: 100%;
    font-family: 'Montserrat';
    font-size: 25px;
    font-weight: 100;
}
input.srch-input:focus
{
    outline: none;
}
.srch-btn {
    background-color: transparent;
    border: none;
    position: absolute;
    padding: 0;
    right: 38px;
    width: 16px;
    top: 45px;
}
.srch-btn img
{
    width: 100%;
}
.cls-btn
{
    width: 25px;
    height: 25px;
    position: absolute;
    right: 50px;
    top: 50px;
    padding-top: 14px;
    cursor: pointer;
}
span.line1 {
    width: 100%;
    height: 2px;
    background: #fff;
    float: left;
    transform: rotate(45deg);
}
span.line2 {
    width: 100%;
    height: 2px;
    background: #fff;
    float: left;
    transform: rotate(-45deg);
    margin-top: -2px;

}
a.srvcs-btn {
    width: 175px;
    height: 50px;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Montserrat';
    line-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ca2d1d;
    text-decoration: none;
    border: 3px solid #ca2d1d;
    transition: all .5s;
}
a.srvcs-btn:hover {
    background-color: #ca2d1d;
    color: #fff;
    border-color: #ca2d1d;
}

.hidden {
    display: none;
    /* opacity: 0; */
}

a.nav-link.main-menu-lnk.active {
    text-decoration: underline;
    text-decoration-color: #ca2d1d;
    text-decoration-thickness: 8px;
    text-underline-offset: 8px;
}

.mega_nav_tabs a {
    text-decoration: none;
}

.mega_nav_tabs a:hover {
    color: #ca2d1d ;
}

.dropdown:hover>.dropdown-menu {
	display:block !important;
    -webkit-animation: fadeIn 0.5s;
    animation: fadeIn 0.5s;  
}

.tab-content>.tab-pane{
    -webkit-animation: fadeIn 0.7s;
    animation: fadeIn 0.7s;  
}

img.close-btn {
    position: absolute;
    top: 30px;
    right: 25px;
    left: auto;
}

@media screen and (min-width:1024px) {
    div#main_nav {
        display: grid !important;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px){
    .navbar-nav {
        display: flex;
        flex-direction: column;
        padding-left: revert;
        margin-bottom: auto;
        list-style: none;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        align-content: center;
    }
}

@media screen and (min-width: 1025px){ 
    .mobile-menu {
        display: none;
    }
    .srvc-clm3 {
        overflow-y: scroll;
    }
}

@media screen and (min-width: 320px) and (max-width: 1024px){ 
        .desk-menu {
            display: none;
        }
        .navbar-collapse {
            flex-basis: auto !important;
        }
        ul.navbar-nav {
            position: absolute;
            top: 100%;
            right: 0;
            width: 100%;
            /* height: 100vh; */
            background-color: #050202;
        }
        .navbar>.container-fluid {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            justify-content: space-between;
            flex-direction: row;
            padding-left: 0px;
        }
        #main_nav ul li a.main-menu-lnk {
            font-size: 20px;
            padding: 15px;
        }


        

        nav.mob-nav {
            display: flex;
            background-color: #06172F;
            width: 100%;
            height: 100px;
            position: relative;
        }
        .mob-logo {
            vertical-align: middle;
            text-align: center;
            margin: auto;
        }
        nav {
            height: 0px;
         }
          
          
          #menuToggle {
            width: 100%;
            margin: auto;
            max-width: 100%;
            display: flex;
            flex-direction: row;
            position: relative;
            top: auto;
            right: 0;
            left: auto;
            z-index: 1;
            -webkit-user-select: none;
            user-select: none;
            align-content: center;
            flex-wrap: wrap;
            padding-right: 30px;
            justify-content: flex-end;
            align-items: center;
        }
          
        #menuToggle input {
            display: flex;
            width: 40px;
            height: 32px;
            position: absolute;
            top: -10px;
            right: 29px;
            cursor: pointer;
            opacity: 0;
            z-index: 2;
            flex-wrap: wrap;
            justify-content: flex-end;  
        }
          
          #menuToggle span
          {
            display: flex;
            width: 29px;
            height: 2px;
            margin-bottom: 5px;
            position: relative;
            background: #ffffff;
            border-radius: 3px;
            z-index: 1;
            transform-origin: 5px 0px;
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                        background 0.5s cubic-bezier(0.77,0.2,0.05,1.0),
                        opacity 0.55s ease;
          }
          
          #menuToggle span:first-child
          {
            transform-origin: 0% 0%;
          }
          
          #menuToggle span:nth-last-child(2)
          {
            transform-origin: 0% 100%;
          }
          
          #menuToggle input:checked ~ span
          {
            opacity: 1;
            transform: rotate(45deg) translate(-3px, -1px);
            background: #36383F;
          }

          #menuToggle input:checked ~ span:nth-last-child(3)
          {
            opacity: 0;
            transform: rotate(0deg) scale(0.2, 0.2);
          }
          
          #menuToggle input:checked ~ span:nth-last-child(2)
          {
            transform: rotate(-45deg) translate(0, -1px);
          }
          
          #menu
          {
            position: fixed;
            top: auto;
            right: 0px;
            width: 100vw;
            height: 100vh;
            box-shadow: 0 0 10px #85888c;
            margin: -65px 0 0 -50px;
            list-style-type: none;
            padding: 90px 50px 15px 45px;
            background-color: #F5F6FA;
            -webkit-font-smoothing: antialiased;
            transform-origin: 0% 0%;
            transform: translate(130%, 0);
            transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0) 100ms;
            overflow-y: scroll;
          }
          
          #menu li
          {
            padding: 7px 0;
            transition-delay: 2s;
            margin-bottom: 10px;
            border-bottom: 1px solid #d6d6d6;
            display: flex;
            justify-content: space-between;
          }
          
          #menuToggle input:checked ~ ul, #menu a:checked ~ ul
          {
            transform: none;
          }
          
          #menuToggle span:nth-child(3), #menuToggle span:nth-child(4) {
            width: 18px;
            margin-left: 9px;
        }
        #menuToggle input:checked ~ span:nth-last-child(2) {
            transform: rotate(-45deg) translate(0, -1px);
            width: 29px;
            margin-left: 0;
        }
        .hide {
            display: none;
        }
        .show {
            display: block;
        }
        img.svg-icon {
            width: 10px;
            margin-left: 5px;
            float: right;
            margin-right: 10px;
        }
        #menu ul {
            list-style-type: none;
            padding-left: 8px;
            margin-bottom: 5px;
        }
        
        ul#menu a {
            text-decoration: none;
            font-weight: 600;
            text-transform: uppercase;
            font-family: 'Montserrat', sans-serif;
            font-size: 18px ;
            color: #36383F;
        }
        
        ul.abt-drop-menu a, .srvcsmain-drop-menu a, .career-drop-menu a {
            font-size: 15px !important;
            font-weight: 400 !important;  
        }
        
        ul.glbl-drop-menu a, .intg-drop-menu a, .cstmb-drop-menu a, .inds-drop-menu a {
            font-size: 14px !important;
            font-weight: 400 !important;
        }

        .rotate {
            transform:rotate(180deg);
        }
        ul.abt-drop-menu li, .srvcsmain-drop-menu li, .career-drop-menu li {
            margin-bottom: 0px !important;
        }
        .z-indx {
            z-index: 0;
        }
        .mobile-menu {
            position: fixed;
            width: 100%;
            z-index: 9;
            top: 0;
        }
        .txt-color{
            color: #ca2d1d !important;
        }

        ul#menu a.active  {
            color: #ca2d1d;
        }

        .trnsfrm
        {
            transform: translate(0%, 0) !important;
        }
        

        .revrs-span {
            opacity: 1 !important;
            transform: none !important;
            background: rgb(255, 255, 255) !important;
        }
        .revrs-span:nth-last-child(2) {
            opacity: 1 !important;
            transform: none !important;
            background: rgb(255, 255, 255) !important;
            width: 18px !important;
            margin-left: 9px !important;
        }
        .revrs-span:nth-last-child(3) {
            opacity: 1 !important;
            transform: none !important;
            background: rgb(255, 255, 255) !important;        
        }

}

@media screen and (max-width: 767px){
    .srch-box a.txt {
        display: none;
    }
    .srch-box:hover::after {
        width: 40px;
    }
    .search-dv {
        margin-left: 0px;
        position: absolute;
        top: 0;
        right: 80px;
    }
    .cls-btn {
        top: 15px;
        right: 15px;
    }
    input.srch-input {
        font-size: 16px;
    }
    .search_div > .srch-row {
        width: 90%;
    }
    .search_div > .srch-row img {
        display: none;
    }
    .search_div .srch-row .col-12 {
        padding: 15px 15px;
    }
    .srch-box::before, .srch-box::after {
        display:none
        }
    h3.found-txt {        
        font-size: 27px;
    }
    .blg-srch-rsult .blog-inside-page {        
        padding: 30px;
    }
}



@media screen and (min-width:768px) and (max-width:1279px){

    .search-dv {
        margin-left: 0px;
        position: absolute;
        top: 0;
        right: 100px;
    }
    
    .cls-btn {
        top: 15px;
        right: 15px;
    }
    input.srch-input {
        font-size: 16px;
    }
    .search_div > .srch-row {
        width: 94%;
    }
    .search_div > .srch-row img {
        display: none;
    }
    .srch-box img {
        padding-left: 6px;
    }

}

.mapContainer{
  height: 600px;
  width: 100%
  }

  button {
    color: #1d1d1d;
    margin: 6px 0;
    align-items: center;
    border-radius: 32px;
    cursor: pointer;
    display: flex;
    height: 32px;
    padding: 0 12px;
    border: 1px solid #dadce0;
    background: transparent;
    font: inherit;
    list-style: none;
    outline: 0;
    overflow: visible;
    vertical-align: baseline;
    background: #fff;
  }

  .filter-group {
    padding: 0.8rem 10px;
    font-size: 0.8rem;
    background: #4998ff;
    text-transform: uppercase;
    color: white;
  }

  .title-filters {
    font-size: 1.1rem;
  }

  .filters-list button {
    position: relative;
    text-align: left;
    width: 100%;
    border-radius: 0;
    padding: 1.5rem 10px;
    margin: 0;
    border: none;
  }

  .active-icon-wrapper {
    width: 21px;
    height: 21px;
    display: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' focusable='false' style='width: 21px;height: 21px;'%3E%3Cpath d='M4.29 12.104c-.277-.308-.75-.332-1.06-.054-.306.278-.33.752-.053 1.06l4.485 4.963c.29.322.795.33 1.096.017L20.414 6.003c.288-.298.28-.773-.02-1.06-.297-.288-.772-.28-1.06.02L8.237 16.47 4.29 12.105z' fill='%234998ff' %3E%3C/path%3E%3C/svg%3E");
  }

  .active-filter button {
    background: rgb(73, 152, 255, 0.15);
  }

  .active-filter .active-icon-wrapper {
    display: block;
  }

  .flex-grow {
    flex-grow: 1;
  }

  .active-icon-wrapper {
    width: 21px;
    height: 21px;
    display: none;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' focusable='false' style='width: 21px;height: 21px;'%3E%3Cpath d='M4.29 12.104c-.277-.308-.75-.332-1.06-.054-.306.278-.33.752-.053 1.06l4.485 4.963c.29.322.795.33 1.096.017L20.414 6.003c.288-.298.28-.773-.02-1.06-.297-.288-.772-.28-1.06.02L8.237 16.47 4.29 12.105z' fill='%2300a862' %3E%3C/path%3E%3C/svg%3E");
  }

  .icon-country {
    width: 28px;
    height: 28px;
    margin-right: 12px;
    vertical-align: middle;
    position: relative;
    background-size: contain;
  }

  .icon-UAE {
    background-image: url("https://cdn-icons-png.flaticon.com/512/323/323301.png");
  }

  .icon-India {
    background-image: url("https://cdn-icons-png.flaticon.com/512/323/323303.png");
  }

  .woosmap-webapp-container div {
    z-index: 1 !important;
}
.new-section
{
    padding-bottom: 200px;
    padding-left:80px;
}
.new-section > div > .row{
    margin: 0px;
}
.news-hd-col1 .dot {
    width: 12px;
    height: 12px;
    background: #000;
    margin-right: 15px;
    float: left;
    border-radius: 50%;
    margin-top: 3px;
}
.news-hd-col1 .sec-name-ttl {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.015em;
    color: #000;
    margin-bottom: 15px;
}
.news-ttl
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 89px;
    letter-spacing: -0.015em;
    color: #06172F;
    padding-bottom: 55px;
}
.view-btn
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    letter-spacing: -0.015em;
    color: #06172F;
    position: relative;
    padding-right: 0;
    text-decoration: none;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;
}
.view-red-btn {
    width: 51px;
    height: 51px;
    background: #CA2D1D;
    border-radius: 50%;
    display: flex;
    cursor: pointer;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    margin-left: 20px;
}

.view-red-btn .icon.icon--left {
    position: absolute;
    top: 0;
    left: -60px;
    width: 100%;
    height: 100%;
    z-index: 99999;
}
.view-btn:hover .servc_btn-arrow {
    transform: translateX(60px);
}
.view-red-btn .icon-container {
    margin-top: -5px;
}

a.view-btn:hover {
    color: #ca2d1d;
}s
.view-red-btn img
{
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;
}
.news-hd-col2 {
    display: flex;
    align-items: center;
}


.news-sld .slick-list {
    padding:  0px 100px 0px 0px !important;
}
.news-sld .slick-arrow:hover 
{
    background-color: #000;
    transition:0.3s all ease-in;
}
.news-sld .slick-prev:hover:before
{
    
    background-image: url('../img/left-arrow.png');
}
.news-sld .slick-next:hover:before
{
    background-image: url('../img/right-arrow.png');
}
.news-sld .slick-prev {
    left: 0px;
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #000;
    border-radius: 50%;
    z-index: 1;
    top: auto;
    bottom: -130px;
}
.news-sld .slick-prev:before
{
    content: '' ;
    background-image: url('../img/black-left-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;          
}
.news-sld .slick-next {
    
    left: 80px;
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #000;
    border-radius: 50%;
    top: auto;
    bottom: -130px;
    z-index: 1;
}
.news-sld .slick-next:before
{
    content: '' ;
    background-image: url('../img/black-right-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;
}
.news-item {
    padding-right: 60px;
}
.nw-prt1 {
    border-top: 1px solid rgb(0 0 0 / 10%);
}
.nws-dates {
    width: 95%;
    float: left;
    display: flex;
    align-items: center;
    padding-top: 5px;
}
.nws-dates .dt
{
    height: 36px;
}
.nws-dates p {
    float: left;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    letter-spacing: -0.015em;
    color: #06172F;
    opacity: 0.5;
    margin-right: 5px;
    margin-bottom: 0px;
}
.add-btn {

    width: 13px;
    height: 13px;
    float: right;
    margin-top: 16px;
    padding-top: 7px;
    cursor: pointer;
    display: none;
}
.add-btn .frst-spn
{
    transform: rotate(90deg);
} 
.add-btn span {
    width: 100%;
    height: 1px;
    background: #000;
    float: left;
    margin-top: -2px;
}
.nw-prt2
{
    width: 100%;
    float: left;
}

.nw-prt2 a {
    text-decoration: none;
}

.nw-prt2 h2
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    letter-spacing: -0.015em;
    color: #06172F;
}
.new_para
{
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    letter-spacing: -0.015em;
    color: #06172F;
    opacity: 0.4;
}
.nw-prt3 {
    width: 100%;
    float: left;
    
}
.nw-prt3 img
{
    width: 100%;
    margin-bottom: 15px;
}
.no-pdng
{
    padding: 0px;
}
.no-margin
{
    margin:0px;
}
@media only screen and (min-device-width: 1910px) and (max-device-width: 1920px) {
    .new-section
    {
        padding-left: 355px;
    }
}
@media only screen and (min-device-width: 1590px) and (max-device-width: 1600px) {
    .new-section
    {
        padding-left: 197px;
    }
}
@media only screen and (min-device-width: 1430px) and (max-device-width: 1440px) {
    .new-section
    {
        padding-left: 125px;
    }
}
@media only screen and (min-device-width: 1270px) and (max-device-width: 1280px) {
    .new-section
    {
        padding-left: 40px;
    }
}

a.blg-view-btn {
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.015em;
    color: #06172f;
    padding-bottom: 6px;
    border-bottom: 2px solid #fff;
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 5px;
    text-decoration-color: #06172f;
}

@media screen and (min-width:320px) and (max-width:768px){ 
    #desk-btn {
        display: none;
    }
    .view-red-btn {
        width: 58px;
        height: 58px;
    }
}

@media screen and (min-width: 1025px){ 
    #mobl-btn {
        display: none;
    }
}


.beliefs_main
{
    background: #06172F;
    padding-bottom: 100px;
}
.belief-box
{
    float:left;
    width: 46%;
    margin-right: 30px;
}
.belief-sub-ttl
{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    color: #fff;
    margin-top: 20px;
}
.belief_img
{
    width:100%;
}

.blfs-col2
{
    padding-left: 30px;
}
ul.mission-list {
    list-style-image: url(../img/boomerrang-icon.png);
    list-style-type: none;
    padding-left: 22px;
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 14px;
    line-height: 25px;
    letter-spacing: -0.015em;
    color: #212529;
}

div#our-beliefs .srvc_contact {
    padding: 30px;
}

@media screen and (min-width:320px) and (max-width:768px){
    .beliefs_main {
        padding: 50px 20px 40px 20px !important;
        text-align: center;
    }
    .blfs-col2 {
        padding-left: 0px;
    }
    h2.srvc-ttl br {
        display: none;
    }
    .belief-box {
        width: 100%;
        margin-right: 0px;
        margin-bottom: 20px;
        padding-top: 10px;
    }
    .belief-box .srvc_contact {
        text-align: left;
    }
    
}

@media screen and (min-width:1024px) and (max-width:1279px){
    .beliefs_main {
        padding: 100px 15px;
    }
    .belief-box {
        width: 42%;
    }
    .blfs-col2 {
        padding-left: 0px;
    }

}



@media screen and (min-width: 1024px){ 
    .mobb-tabs {
        display: none;
    }
}


@media screen and (min-width:768px) and (max-width:1024px){
    .sld-cnt
    {
        width: 65%;
    }
}

@media screen and (min-width:320px) and (max-width:767px){
    .sld-cnt .spt-btn {
        width: 100%;
        margin-bottom: 20px;
    }
    .explore-btn {
        width: 100%;
    }
    
}

@media screen and (min-width:320px) and (max-width:768px){
    
    /* #up {
        transform: none !important;
    } */
    #home .service_main {
        padding-top: 50px;
    }
    .abt-col2 {
        padding-left: 50px;
        margin-top: 50px;
    }
    .jrny-col1 h2 {
        font-size: 36px;
        line-height: 48px;
        padding-left: 0;
        text-align: center;
        color:#06172F;
    }
    .jrny-col2 {
        padding-top: 0;
    }
    
    .sld-cnt .spt-ttl {
        font-family: 'Montserrat', sans-serif;
        font-size: 36px;
        line-height: 48px;
        font-weight: 700;
    }
    .sld-cnt {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
        margin: auto;
        padding-top: 35%;
    }
    .spotlight-sld .sld {
        padding-left: 0px;
    }
    .sec-ttl, .abt-sld h2 {
        font-size: 20px !important;
        line-height: 26px !important;
    }
    .sld-cnt .spt-dcrp {
        display: none;
    }
    .spotlight-sld .slick-prev {
        left: 5%;
        right: auto;
        bottom: 7%;
        margin: auto;
    }
    .spotlight-sld .slick-next {
        right: auto;
        bottom: 7%;
        margin: auto;
        left: 23%;
    }
    .about-main.pdng-tb {
        padding-top: 47px;
        padding-right: 15px;
        padding-left: 15px;
        padding-bottom: 50px;
    }
    .abt-col1 {
        padding-right: 20px !important;
        padding-left: 20px;
        text-align: center;
    }
    .dcrb {
        font-size: 14px !important;
    }
    .sec-name-dv {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
    }
    
    .abt-col2 {
        text-align: center;
        padding-left: 20px !important;
        margin-top: 50px;
        padding-right: 17px;
    }
    ul.mission-list {
        list-style-position: inside;
        padding-left: 0px !important;
    }
    ul.mission-list li {
        width: -moz-fit-content;
        text-align: center;
        width: fit-content;
        margin: auto;
    }
    .about-sld .slick-dots {
        text-align: center !important;
    }
    .abt-sld p {
        font-size: 14px;
    }
    .journey-section {
        display: none;
    }
    div#home>#techno {
        margin-top: 10px;
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 50px;
    }
    .mobb-tabs {
        display: none;
    }
    .abt-bg-vct {
        display: none;
    }
    #home .whyCp-col1 {
        padding: 0 !important;
        height: auto !important;
        background-color: #fff !important;
    }

    div#about-pag #techno {
        padding-right: 20px;
        padding-left: 20px;
        padding-bottom: 50px;
    }
    #home .nav-tabs {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 0;
        list-style: none;
        flex-direction: row;
        align-content: center;
        align-items: center;
        text-align: center;
        justify-content: space-between;
    }
    .mobb-tabs {
        padding-bottom: 50px;
    }
    .jrny-mobl-ttl {
        font-size: 36px;
        line-height: 48px;
        padding-left: 0;
        text-align: center;
        color: #06172F !important;
    }
    .clint-box1, .clint-box2 {
        padding: 40px;
        background-color: #095792;
    }
    .clint-box1 p, .clint-box2 p {
        font-family: Montserrat;
        font-size: 30px;
        font-weight: 500;
        line-height: 39px;
        letter-spacing: -0.015em;
        text-align: left;
        color: #fff;
    }
    .clint-box1 {
        margin-bottom: 20px;
    }
    .cap_tabs_sec {
        margin-top: 20px !important;
        /* padding-left: 15px;
        padding-right: 15px; */
    }
    #home .cap_tabs_sec li a {
        color: #095792 !important;
    }
    #home .cap_tabs_sec li a.active {
        border-bottom: 2px solid #CA2D1D !important;
    }
    #home .whyCp-col1::before {
        display: none;
    }
    #home #myTabContent .tab-pane {
        padding: 20px 0px 0px 0px;
    }
    #home .cap_tabs_sec li {
        text-align: center;
        margin-right: 0;
    }
    .service_main.pdng-tb {
        /* padding-top: 51px !important; */
        padding-right: 20px !important;
        padding-left: 20px !important;
    }
    .srvc-ttl {
        font-size: 36px;
        line-height: 48px;
        padding-left: 0;
        text-align: center;
    }
    .air_srvc_para {
        color: #095792;
        width: 100% !important;
        text-align: center;
    }
    .custm-brokg {
        margin-top: 0px;
        padding-right: 12px;
        padding-top: 15px;
    }
    .gloabl-fght {
        margin-top: 81px;
        padding-right: 12px;
    }
    .sply-chn {
        margin-top: 140px;
        padding-right: 12px;
    }
    .cstm-hide {
        visibility: hidden;
        display: none;
    }
    .indstry-factry {
        margin-top: -220px !important;
        padding-right: 12px !important;
    }
    .vlu-spacmange {
        margin-top: 50px !important;
        padding-right: 12px !important;
    }
    img.servc_bg {
        display: none;
    }
    .sec-hd {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
    }
    #home .sector-main {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    #home .sector-sld {
        margin: auto;
        margin-top: 85px;
    }
    #home .sec-sld-row {
        padding-right: 8px;
        padding-left: 8px;
    }
    #home .sector-sld .slick-slide img {
        width: 237px;
        text-align: center;
        margin-bottom: 290px;
        margin-left: auto;
        margin-right: auto;
        float: none !important;
        height: auto;
    }
    #home .sec-para {
        width: 100%;
    }
    #home .sector-sld-nav .slick-track {
        display: none;
    }
    #home .sec-subttl {        
        margin-top: 28px;
        text-align: center;
    }
    #home .sec-sld-ttl {       
        font-size: 36px;
        line-height: 48px;
    }
    #home .sectr-sld-contnt {
        text-align: center;
    }
    #home .sld-counter {
        top: 185px;
        bottom: auto;
        left: auto;
        right: 13px;
        z-index: 0;
    }
    #home .sec-para {
        font-size: 14px;
        line-height: 25px;
    }
    #home .sector-sld .slick-next {
        top: auto;
        left: 53%;
        right: 0px;
        bottom: -55px;       
    }
    #home .sector-sld .slick-prev {
        top: auto;
        right: 52%;
        left: auto;
        bottom: -55px;
    }
    #home .explore-sec-btn {
        margin-top: 60px;
        width: 100%;
    }
    #home .case_Study_name {
        padding-left: 20px;
        padding-top: 45px;
        background: #06172F;
    }
    #home .cs-ttl {
        font-size: 36px;
        line-height: 40px;
    }
    
    #home .cs-item {
        padding-right: 25px;
        margin-left: 0px;
    }
    #home .cs-col-1 {
        width: 100%;
    }
    #home .cs-col-2 {
        width: 100%;
        padding-left: 0;
        padding-top: 20px;
    }
    #home .case_Study_name {
        padding-top: 50px;
        padding-bottom: 160px;
    }
    #mobil-img .slick-slide img {
        width: 235px;
        margin: auto;
    }
    .testimonial {
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 55px;
        padding-bottom: 125px;
    }
    #home .test-col1 {
        text-align: center;
    }
    #home .testi-sld {
        padding-left: 0;
    }
    #home .testi-para {
        font-size: 22px;
        line-height: 28px;
        width: 100%;
    } 
    #home .testi-sld .slick-next {
        top: auto;
        left: 53%;
        right: 0px;
        bottom: -100px;
    }
    #home .testi-sld .slick-prev {
        top: auto;
        right: 52%;
        left: auto;
        bottom: -100px;
    }  
    #home .testi-sld {
        margin-top: 15px;
    }
    #home .qut-img {
        padding-left: 0px;
        margin-top: 30px;
    }
    #home .test-ttl {
        margin-bottom: 35px;
    }
    #home .lc-hd-col > div {
        width: 100%;
        margin: auto;
        text-align: center;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
    }
    .lc-hd {
        font-size: 36px;
        line-height: 48px;
        text-align: center;
        margin-bottom: 20px;
    }
    #home .cul-col1, #home .cul-col3 {
        width: 40%;
    }
    #home .cul-col2 {
        width: 10%;
    }
    #home .cul-img {
        width: 100%;
    }
    #home .life-capricorn {
        position: relative;
        padding-bottom: 340px;
        padding-top: 60px;
        padding-right: 8px;
        padding-left: 8px;
    }
    #home .cul-para {
        width: 90%;
        position: absolute;
        top: auto;
        bottom: 135px;
        padding-top: 0px;
        text-align: center;
        margin: auto;
        right: auto;
        width: 90%;
        margin-left: -3% !important;
    }    
    #home .cul-ttl, .opp-ttl {
        font-size: 70px;
        line-height: 89px;
        left: -111px;
        top: 60px;
    }
    #home img.packge-img {
        position: absolute;
        top: -10px;
        left: 0;
        right: auto;
    }
    #home .cul-col3 {
        padding-top: 130px;
    }
    #home img.conversn-img {
        top: 60%;
    }
    #home .life-capricorn .tab-pane {
        padding: 40px 0px 0px 0px !important;
    }
    #home .opp-ttl {
        font-size: 50px;
        line-height: 59px;
        left: -145px;
        top: 65px;
    }
    .growth-img2 {
        margin-top: -35px;
    }
    
    .opportu-img2 {
        margin-top: -44px;
    }
    
    #growth .cul-para, #opportunities .cul-para {
        bottom: 135px;
    }
    
    #growth img.conversn-img, #opportunities img.conversn-img  {
        top: 67%;
    }
    #home .cul-row {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
    }
    div#news-sec {
        padding-left: 20px;
        padding-right: 0px;
        padding-bottom: 130px;
        padding-top: 60px;
    }
    .whts-happn-txt {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
    }
    #home h2.news-ttl {
        font-size: 36px;
        line-height: 48px;
        text-align: center;
        padding-bottom: 25px;
    }
    #home .nw-prt2 h2 {
        font-size: 22px;
        line-height: 28px;
    }
    #home a.blg-view-btn {
        font-size: 16px;
    }
    #home .news-item {
        padding-right: 35px;
    }
    div#mobl-btn {
        display: flex;
        align-items: center;
        flex-direction: row;
        flex-wrap: nowrap;
        align-content: center;
        justify-content: center;
        padding-bottom: 40px;
    }
    .news-sld .slick-prev {
        top: auto;
        right: 56%;
        left: auto;
    }
    .news-sld .slick-next {
        top: auto;
        left: 49%;
        right: 0px;
    }

}

@media only screen and (min-width:768px) and (max-width:1023px) {
    #home .sector-sld .slick-prev, #home .testi-sld .slick-prev {    
        left: 42%;
        bottom: -35px;
    }
    #home .sector-sld .slick-next, #home .testi-sld .slick-next {    
        right: 41%;
        bottom: -35px;
    }
    #home .sector-sld .slick-slide img {
        width: 273px;
    }
    .spotlight-sld .slick-next {
        margin: 0;
    }
    #home .testi-sld .slick-next, #home .testi-sld .slick-prev {
        bottom: -100px !important;
    }
}

@media only screen and (min-width:768px) and (max-width:1024px) {
    .sld-cnt .spt-btn {
        width: 25%;
        margin-bottom: 20px;
    }
    a.rd-btn.explore-btn {
        width: 25%;
        margin: auto;
    }
    #home .nav-tabs {
        justify-content: space-around;
    }
    #techno #myTabContent {
        width: 80%;
        margin: auto;
    }
    #home .explore-sec-btn {
        width: 25%;
        margin: 30px auto 0px;
    }
    
    #home .sld-counter {       
        right: 25%;
    }
    
    .news-sld .slick-prev {
        left: 39%;
    }
    .news-sld .slick-next {
        right: 41%;
    }
    
    .life-capricorn {
        width: 100%;
        height: 900px;
    }
    #opportunities .cul-para {
        bottom: 135px;
    }
    #growth .cul-para {
        bottom: 135px;
    }
    #culture p.cul-para {
        bottom: 135px;
    }
    #home .life-capricorn {
        padding-bottom: 0;
    }
    /* img.cul-img.cultr-img2 {
        margin-top: 120px;
    } */
    .spotlight-sld .slick-next {
        left: 150px;
    }
    .spotlight-sld .slick-prev {
        left: 70px;
    }
}

@media screen and (min-width:1024px) and (max-width:1279px) {
    .sld-cnt .spt-ttl {
        margin-bottom: 20px;
    }
    .about-main {
        padding-top: 60px;
        padding-left: 20px;
        padding-right: 20px;
    }
    a.rd-btn.explore-btn {
        width: 50%;
        margin: inherit;
    }
    div#our-journey {
        width: 104%;
        margin: auto;
        margin-left: -2%;
    }
    .jrny-col1 {
        padding-top: 59px;
    }
    .jrny-col1 h2 {
        padding-left: 20px;
    }
    .service_main {
        padding-left: 20px;
        padding-right: 20px;
    }
    .sply-chn {
        padding-right: 15px;
    }
    .vlu-add {
        padding-right: 15px;
    }
    .sector-main {
        padding-left: 20px;
        padding-right: 20px;
    }
    #home .explore-sec-btn {
        width: 100%;
    }
    .sector-sld .slick-prev {
        bottom: -40px;
        right: 75px !important;
    }
    .sector-sld .slick-next {        
        right: 0px;
        bottom: -40px !important;
    }
    .sld-counter {
        right: 475px !important;
    }
    .img-item img {
        padding-left: 20px;
    }
    .testi-sld .slick-next {
        right: 15px;
    } 
    .testi-sld .slick-prev {
        left: -13px;
    }   
    .test-col1 .slick-list {
        width: 90%;
        padding-left: 3%;
    }
    #home .life-capricorn {
        padding-bottom: 50px;
        height: auto;
        padding-left: 20px;
        padding-right: 20px;
    }
    .lc-hd-col > div {
        width: 18%;
    }
    .cul-col3 {
        overflow: hidden;
    }
    div#mobl-btn {
        display: none;
    }
    .news-sld .slick-prev {
        left: 0px;
    }
    .news-sld .slick-next {
        left: 80px;
    }
    img.sec-img {
        width: 45vw;
        height: auto;
    }
    .sld-counter {
        left: 315px;
    }
    
}
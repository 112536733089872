@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@700&family=Roboto:wght@100&display=swap');

.enq-btn {
    position: fixed;
    top: 25%;
    right: 0px;
    font-size: 12px;
    text-align: center;
    transform: rotate( -180deg );
    border-radius: 0px;
    background-color: #c13529;
    height: 115px;
    line-height: 0px;
    width: 20px;
    font-weight: 600;
    box-shadow: 2px 0 25px -5px rgb(0 0 0 / 75%);
    writing-mode: tb-rl;
    padding: 5px 15px;
    white-space: nowrap;
    z-index: 9;
    transition: all 0.5s ease-out 0s;
    color: #fff;
    text-decoration: none;
    font-size: 14px;
    font-family: 'Montserrat';
    text-transform: uppercase;
}

a.enq-btn:hover {
    background-color: #fff;
    color: #c13529;
}

.pop-up div#get-in-touch {
    height: 100vh;
    width: 100%;
    z-index: 999;
    margin: 0 auto;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-y: auto;
    overflow-x: none;
    -webkit-overflow-scrolling: touch;
    transform: none;
    max-width: none;
    pointer-events: all;
    padding: 10px;
    display: none;
    background-color: #0000005c;
}


.contact-frm-col {
    padding-top: 100px;
    padding-bottom: 100px;
    max-width: 1200px;
    width: 50%;
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    box-shadow: 0px 0px 4px 0px;
}



button.close {
    background-color: #fff0;
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 4;
    float: right;
    font-size: 3rem;
    font-weight: 300;
    line-height: 1;
    color: #06172f;
    opacity: 1;
    width: auto;
    padding-bottom: 5px;
    border: 0;
}
select.select-input-fld {
    border: none;
    width: 96%;
    margin-right: 4%;
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 18px;
    padding-bottom: 5px;
    margin-bottom: 40px;
    border-bottom: 1px solid #cecece;
}
select:focus-visible {
    outline: 0;
}



.pop-up .input-fld, .select-input-fld {
    height: 45px;
}

.pop-up .input-fld::placeholder, .pop-up .select-input-fld::placeholder, .pop-up .txtarea::placeholder {
    /* font-size: 15px !important; */
    padding-left: 0px;
}
.pop-up select.select-input-fld {
    /* font-size: 15px !important; */
    padding-left: 0px;
}
.pop-up .contact-frm-col {    
    background-color: #fff !important;
}

.pop-up .txtarea {
    padding-top: 10px;
}
 
@media screen and (min-width:320px) and (max-width:768px) {
    .contact-frm-col {
        width: 100%;
        height: 100%;
    }
    .fld_dv {
        width: 100% !important;
    }   
    button.close {
        top: 15px;
        right: 15px;
    }
    
    .contact-frm-col form {
        width: 90%;
    }
    .enq-btn {
        top: 35%;
    }
}
@media screen and (min-width:769px) and (max-width:1024px) {
    .contact-frm-col {
        width: 90%;
    }
}

@media screen and (min-width:320px) and (max-width:767px) {
    .pop-up .contact-frm-col {
        background-color: #fff !important;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-wrap: nowrap;
        align-content: center;
    }
    .pop-up h2.cntct-ttl {
        margin-bottom: 0;
    }
    
}
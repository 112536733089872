.privcy-main {
    padding: 50px 15px;
}
.privcy-main p {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
}
.privcy-main li {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
}
.privcy-main h2.awrd-ttl {
    font-size: 22px;
}
.privcy-main h2.awrd-ttl:first-child {
    font-size: 30px;
}
.privcy-main .logo {
    margin-bottom: 20px;
    margin-top: 25px;
}

@media screen and (min-width:320px) and (max-width:768px){
    .privcy-main {
        padding: 0px 15px 50px;
        text-align: center;
    }
}
/*-------------------------------------------------------------------- About Css Start Here */
.about-main
{
    position: relative;
    padding-bottom: 100px;
}
.abt-col1,.abt-col2
{
    opacity:0;
}
.abt-bg-vct
{
    position: absolute;
    left: auto;
    right: 0;                       
    top: auto;
    bottom: -120px;
    z-index: -1;
    width: 51.5%;
}

/*----------------------- Part 1 */
.abt-col1
{
    padding-right: 180px;
}
.abt-col2 
{
    padding-left: 60px;
}

.dot
{
    width: 12px;
    height: 12px;
    background: #06172F;
    margin-right: 15px;
    float: left;
    border-radius: 50%;
    margin-top: 2px;
}
.sec-name-ttl
{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.015em;
    color: #06172F;
    margin-bottom: 15px;
}
.sec-ttl 
{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 30px;
    letter-spacing: -0.015em;
    color: #06172F;
    line-height: 39px;
}
.dcrb
{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.015em;
    color: #06172F;
    line-height: 29px;
} 
.explore-btn
{
    margin-top: 30px;
}
.world-img
{
    width: 46px;
    margin-bottom: 25px;
}
.abt-sld h2
{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 30px; 
    height:39px;
    color: #06172F;
    letter-spacing: -0.015em;
}
.abt-sld p
{
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: -0.015em;
}
.about-sld .slick-dots li {
    width: 12px;
    height: 12px;    
    background: #fff;
    border: 2px solid #06172F;
}
.about-sld .slick-dots li.slick-active {
    border: 2px solid #CA2D1D;
    background: #CA2D1D;
}
.about-sld .slick-dots li button:before
{
    display: none;
}
.about-sld .slick-dots
{
    bottom: -24px;
    text-align: left;
}

/*----------------------- Part 2 - Our Journey of Growth */
.no_mrgin
{
    margin: 0px;
}
.journey-section {
    position: relative;
    width:100%;
    padding-top: 60px;
    display: none;
}
.journey-section::after {
    content: '';
    position: absolute;
    top: auto;
    bottom: 0;
    left: auto;
    right: 0;
    background: #06172f;
    width: 50%;
    height: 214px;
    z-index: -1;
}
.jrny-col1 {
    padding-top: 50px;
    padding-right: 0px;
    padding-left: 0px;
}
.jrny-col2 {
    padding-top: 153px;
    padding-left: 0px;
    margin-left: -1px;
    padding-right: 0px;
}
.jrny-col1 h2
{
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 70px;
    line-height: 71px;
    letter-spacing: -0.015em;
    color: #06172F;
    opacity: 0;
}
.innr-dv1
{
    width: 62%;
    float: left;
    height: 327px;
}
.innr-dv1 .white-spc1
{
    width: 100%;
    height: 25.5%;
    float: left;
    background: #fff;
}
.frm-box1
{
    width: 100%;
    height: 62%;
    float: left;
    background: #095792;
    padding: 40px;
    transform: translateY(30px);
    opacity:0;
}
.clnt-dtl
{
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 27px;    
    line-height: 39px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}
.clnt-arrow
{
    width: 25px;
    height: 25px;
    float: right;
    margin-top: -40px;

}
.innr-dv1 .blue-box1
{
    width: 100%;
    height: 13%;
    float: left;
    background: #06172F;
}

.innr-dv2
{
    width: 38%;
    float: left;    
    height: 327px;

}
.innr-dv2 .white-spc2
{
    width: 100%;
    height: 62%;
    float: left;
    background: #fff;
}

.innr-dv2 .blue-box2
{
    width: 100%;
    height: 38.5%;
    float: left;
    background: #06172F;
}

.innr-col2-dv1
{
    width: 62%;
    float: left;
    height: 376px;
}
.frm-box2
{
    width: 100%;
    height: 53.8%;
    float: left;
    background: #095792;
    padding: 40px;
    transform: translateY(50px);
    opacity:0;
}
.innr-col2-dv1 .blue-box3
{
    width: 100%;
    height: 46.1%;
    float: left;
    background: #06172F;
}

.innr-col2-dv2
{
    width: 38%;
    float: left;
    height: 376px;
}
.innr-col2-dv2 .trans-spc
{
    width: 100%;
    height: 43%;
    float: left;
    background: rgba(255, 255, 255, 0);
}
.innr-col2-dv2 .blue-box4
{
    width: 100%;
    height: 57%;
    float: left;
    background: #06172F;
}

.frm-clnt-sld button
{
    display: none !important;
}
.to-clnt-sld .slick-prev {

    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #fff;
    border-radius: 50%;
    z-index: 1;
    top: auto;
    bottom: -160px;
    left: auto;
    right: 40px;
}
.to-clnt-sld .slick-prev:before
{
    content: '' ;
    background-image: url('../img/left-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;          
}
.to-clnt-sld .slick-next {
    
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #fff;
    border-radius: 50%;
    top: auto;
    bottom: -160px;
    left: auto;
    right: -40px;
    z-index: 1;
}
.to-clnt-sld .slick-next:before
{
    content: '' ;
    background-image: url('../img/right-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;
}

.to-clnt-sld .slick-arrow:hover 
{
    background-color: #fff;
    transition:0.3s all ease-in;
}
.to-clnt-sld .slick-prev:hover:before
{
    background-image: url('../img/black-left-arrow.png');
}
.to-clnt-sld .slick-next:hover:before
{
    background-image: url('../img/black-right-arrow.png');
}
/*-------------------------------------------------------------------- About Css End Here */

@media only screen and (min-device-width: 1910px) and (max-device-width: 1920px) {
    .abt-bg-vct
    {
        width: auto;
    }
}


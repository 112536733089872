
.whyCp_row
{
    margin: 0px;
}
.whyCp-col1
{
    background-color:#06172F;
    padding:140px 100px 50px 80px;
    height: 770px;
    position: relative;
}
.whyCp-col1::before
{
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 40px;
    background-color: #fff;
}
.whyCp-col1 .sec-name-ttl{
    color: #fff;
}
.whyCp-col1 .dot {
    background-color: #fff;
}
.whyCp-col1 .sec-ttl
{
    color: #fff;
}
.cap_tabs_sec {
    margin-top: 50px;
}
.cap_tabs_sec .nav-tabs
{
    border: none;
}
.cap_tabs_sec li
{
    width: fit-content;
    text-align: center;
    margin-right: 40px;
}
.cap_tabs_sec li a
{
    color: #fff;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    text-decoration: none;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;

}
.cap_tabs_sec li a.active
{
    /* border-bottom:2px solid #E5E5E5; */

    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 16px;
}
.cap_tabs_para
{
    color:#fff;
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
}
.whyCp-col2 {
    padding: 0px;
    height: 770px;
    position: relative;
}
.whyCp-col2 .whyCp_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.abt-whyCp {
    background-color: #095792;
}

@media only screen and (min-device-width: 1910px) and (max-device-width: 1920px) {
    .whyCp-col1
    {
        padding-left:360px;
    }
}
@media only screen and (min-device-width: 1590px) and (max-device-width: 1600px) {
    .whyCp-col1
    {
        padding-left:207px;
    }
}
@media only screen and (min-device-width: 1430px) and (max-device-width: 1440px) {
    .whyCp-col1
    {
        padding-left:125px;
    }
}
@media only screen and (min-device-width: 1270px) and (max-device-width: 1280px) {
    .whyCp-col1
    {
        padding-left:40px;
    }
}

.whyCp_main {
    position: relative;
}

.technology-content, .quality-content, .environment-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: #06172F;
    display: none;
    z-index: 1;
    /* padding: 150px 50px 50px 50px; */
}

.heading {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #fff;
    margin-bottom: 14px;
    text-align: center;
}

.tabs-content {
    max-width: 1185px;
    margin: auto;
    width: 80%;
    text-align: center;
}

a.content-close-btn {
    color: #fff;
    text-underline-offset: 5px;
    margin: auto;
    text-align: center;
    display: block;
    padding-top: 50px;
    width: fit-content;
}

a.read-mr-btn, .read-mr-btn-qlty, .read-mr-btn-envrmt, a.read-mr-btn-qlty:hover, .read-mr-btn-envrmt:hover {
    color: #fff;
    text-underline-offset: 5px;
}

.content-inner-div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

@media screen and (min-width:320px) and (max-width:768px){
    
    .whyCp_abt_pg {
        padding-right: 0 !important;
        padding-left: 0 !important;
        padding-bottom: 0 !important;
    }
    .whyCp-col1::before{
        height: 0px;
    }

    .whyCp-col1 {    
        height: auto;        
    }
    .whyCp-col1 {
        padding: 50px 20px 55px 20px;
    }
    .whyCp-col1 .sec-ttl {
        text-align: center;
    }
        
    .cap_tabs_sec li {
        width: -moz-fit-content;
        width: fit-content;
        text-align: center;
        margin-right: 0;
    }

    #myTabContent .tab-pane {
        padding: 40px 0px 0px 0px;
        text-align: center;
    }
    .cap_tabs_para {    
        font-size: 14px;
        line-height: 25px;
    }
    .why_cap_tab {
        padding-left: 0;
        padding-right: 0;
    }
    .content-inner-div {
        position: relative;
        top: 50%;
        min-height: 465px;
        transform: translateY(-50%);
        background-color: #000;
        padding-top: 25px;
        margin: auto;
        padding-bottom: 25px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    a.content-close-btn {
        padding-top: 30px;
    }
}

 @media screen and (max-width:1024px) {
    .cap_tabs_sec .nav-tabs {
        border: none;
        display: flex;
        flex-wrap: nowrap;
        padding-left: 0;
        margin-bottom: 0;
        flex-direction: row;
        align-content: center;
        align-items: center;
        justify-content: space-between;
    }
    #techno #myTabContent {
        width: 100%;
        margin: auto;
    }
    .mbl-img {
        height: auto;
    }
 }


 @media screen and (min-width:800px) and (max-width:1024px){
    .cap_tabs_sec .nav-tabs {
        justify-content: space-around;
    }
    .nws-blg-tab {
        justify-content: center !important;
    }
 }


 @media screen and (min-width:768px) and (max-width:799px){
    .nws-blg-tab {
        justify-content: space-evenly !important;
    }
    .cap_tabs_sec .nav-tabs {
        justify-content: space-evenly;
    }
 }
 

 

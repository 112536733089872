.global_main
{
    background: #095792;
    width: 100%;
}
.glob-ttl
{
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 70px;
    line-height: 89px;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
}
.glob-para {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    line-height: 180%;
    text-align: center;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    margin: 20px 0;
    max-width: 610px;
    margin: 20px auto;
}
.global-btn
{
    margin: auto;
}
.globe-img
{
    width: 100%;
}

@media screen and (min-width:320px) and (max-width:768px){
    .global_main {
        padding-right: 20px;
        padding-left: 20px;
        padding-top: 60px;
    }
    
    .glob-ttl {   
        font-size: 36px;
        line-height: 48px;        
    }
    glob-para {
        font-size: 14px;
        line-height: 25px;
    }
    .global_main .global-btn {
        width: 100%;
    }
}
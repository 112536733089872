.blog-inside-page img {
    width: -webkit-fill-available;
}
.cstm-container.blog-inside-page {
    padding: 100px 0px;
}

.blog-inside-page .sec-ttl {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 40px;
    letter-spacing: -0.015em;
    color: #06172F;
    line-height: 39px;
    padding-bottom: 20px;
}
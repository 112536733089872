.capr-lif {
    background-color: #06172f;
}

.funimg-slider {
    padding-bottom: 100px !important; 
}

.blbg {
    background-color: #06172f;
}
.whyCp_row
{
    margin: 0px;
}
.whyCp-col1
{
    background-color:#06172F;
    padding:140px 100px 50px 80px;
    height: 770px;
    position: relative;
}
.whyCp-col1.bg {
    background-color: #095792;
}

.whyCp-col1::before
{
    content:'';
    position: absolute;
    top: 0;
    left: 0;
    width:100%;
    height: 40px;
    background-color: #fff;
}
.whyCp-col1 .sec-name-ttl{
    color: #fff;
}
.whyCp-col1 .dot {
    background-color: #fff;
}
.whyCp-col1 .sec-ttl
{
    color: #fff;
}
.cap_tabs_sec {
    margin-top: 50px;
}
.cap_tabs_sec .nav-tabs
{
    border: none;
}
.cap_tabs_sec li
{
    width: fit-content;
    text-align: center;
    margin-right: 40px;
}
.cap_tabs_sec li a
{
    color:#fff;
    font-family: 'Montserrat';
    font-size: 18px;
    font-weight: bold;
    line-height: 23px;
    display: block;
    text-decoration: none;
    padding-bottom: 10px;

}
.cap_tabs_sec li a.active
{
    /* border-bottom:2px solid #E5E5E5; */
    text-decoration: underline;
    text-decoration-thickness: 2px;
    text-underline-offset: 16px;
}
.cap_tabs_para
{
    color:#fff;
    font-family: 'Open Sans';
    font-size: 18px;
    font-weight: 300;
    line-height: 32px;
}
.whyCp-col2 {
    padding: 0px;
    height: 770px;
}
.whyCp-col2 .whyCp_img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.capr-lif .whyCp-col1::before {
    height: 0;    
}
.capr-lif .whyCp-col2 {
    height: 720px;
    position: relative;
}


@media only screen and (min-device-width: 1910px) and (max-device-width: 1920px) {
    .whyCp-col1
    {
        padding-left:360px;
    }
}
@media only screen and (min-device-width: 1590px) and (max-device-width: 1600px) {
    .whyCp-col1
    {
        padding-left:207px;
    }
}
@media only screen and (min-device-width: 1430px) and (max-device-width: 1440px) {
    .whyCp-col1
    {
        padding-left:125px;
    }
}
@media only screen and (min-device-width: 1270px) and (max-device-width: 1280px) {
    .whyCp-col1
    {
        padding-left:40px;
    }
    .capr-lif .whyCp-col1 {
        height: 650px;
    }
}

.whyCp_main {
    position: relative;
}

.technology-content, .quality-content, .environment-content {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    background: #06172F;
    display: none;
    z-index: 1;
    /* padding: 150px 50px 50px 50px; */
}

.heading {
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 30px;
    line-height: 39px;
    color: #fff;
    margin-bottom: 14px;
    text-align: center;
}

.tabs-content {
    max-width: 1200px;
    margin: auto;
    width: 80%;
    text-align: center;
}

a.content-close-btn {
    color: #fff;
    text-underline-offset: 5px;
    margin: auto;
    text-align: center;
    display: block;
    padding-top: 50px;
    width: fit-content;
}

a.read-mr-btn, .read-mr-btn-qlty, .read-mr-btn-envrmt, a.read-mr-btn-qlty:hover, .read-mr-btn-envrmt:hover {
    color: #fff;
    text-underline-offset: 5px;
}

.content-inner-div {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
}

.img2, .img3  {
    width: 100%;
    opacity: 0;
}
.img1, .img2, .img3 {
    transition: opacity .8s;
    width: 100%;
}

.img-content {
    overflow: hidden;
}

.img-content img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media screen and (min-width:320px) and (max-width:768px){
    div#about-pag .mobb-tabs .whyCp-col1.bg {
        background-color: #ffffff;
    }
    
    #about-pag .mobb-tabs  .cap_tabs_sec li a {
        color: #095792 !important;
    }
    #about-pag .mobb-tabs  .cap_tabs_sec li a.active {
        border-bottom: 2px solid #CA2D1D !important;
    }
    #about-pag .mobb-tabs  #myTabContent .tab-pane {
        padding: 20px 0px 0px 0px;
    }
    #about-pag .mobb-tabs  .whyCp-col1 {
        padding: 0px 0px 0px 0px;
    }
    div#ourjourney {
        height: 20px;
    }
    
}

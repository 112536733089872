.ocean_freight_services
{
    position: relative;
    background-color: #EFEDEE;
}
.ocean-srvc-col1
{
    padding-top: 150px;
    padding-left: 75px;
}
.ocean_srvc_ttl
{
    font-family: 'Montserrat';
    font-size: 30px;
    line-height: 39px;
    color: #06172F;
    margin-bottom: 30px;
}
.ocean_srvc_para
{
    font-family: 'Open Sans';
    font-size: 18px;
    line-height: 32px;
    font-weight: 300;
    color: #06172F;
    width: 83%;
}
.door
{
    padding-right: 20px;
}
.large-ship
{
    margin-top: 50px;
    padding-left: 20px;
}
.small-ship
{
    /* margin-top: -50px; */
    margin-top: -75px;
    padding-right: 20px;
}
.stand-air
{
    padding-left: 20px;
}
.ocean_servc_bg
{
    position: absolute;
    top: auto;
    bottom: 0px;
    right: 0px;
    width: 31%;
}
.license-spacmange {
    margin-top: -95px;
}
.repacking-spacmange {
    padding-top: 0;
    margin-top: -125px;
}

@media screen and (max-width: 1023px){
    .mob-txt{
        display: block;
    }
    .desk-txt {
        display: none;
    }
}

@media screen and (min-width: 1024px){
    .mob-txt{
        display: none;
    }
    .desk-txt {
        display: block;
    }
}

@media screen and (min-width:1024px) and (max-width:1279px){
    .small-ship {
        margin-top: -50px;
    }
    .ocean-srvc-col1 {
        padding-left: 35px;
    }
}

@media screen and (min-width:320px) and (max-width:768px){
    .mob-txt {
        padding-top: 0px;
        padding-left: 0px;
    }
    .ocean_freight_services {
        padding-top: 50px;
        padding-bottom: 50px;
        padding-left: 20px;
        padding-right: 20px;
        text-align: center;
    }
    .ocean_srvc_para {
        font-size: 16px;
        line-height: 28px;
        width: 100%;
        margin-bottom: 10;
    }
    .repacking-spacmange {
        padding-top: 21px;
        margin-top: 0;
    }
    img.ocean_servc_bg, .land_servc_bg {
    display: none;
}

}
.nws-blg-sec .media_new_sec {
    background-color: #ffffff;
}

#nws-blg-clm {
    background-color: #fff !important;
    height: auto !important;
    padding: 0px;
    max-width: 1200px;
    margin: auto;
}

.nws-blg-sec li a {
    color: #06172f !important;
}

.nws-blg-sec li a.active {
    border-bottom: 2px solid #ca2d1d !important;
    text-decoration: none;
}

.nws-blg-tab {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    flex-direction: row;
    align-content: center;
    justify-content: center;
}
.nws-blg-sec .media_new_sec {
    padding-top: 20px;
}


@media screen and (min-width:320px) and (max-width:768px){
    .nws-land-page .cap_tabs_sec .nav-tabs {
        justify-content: space-evenly;
    }
    
    .nws-land-page .nw-prt2 h2 {
        font-size: 22px;
        line-height: 28px;
        margin-bottom: 25px;
    }
    .nws-land-page .nws-blg-sec .media_new_sec {
        padding-top: 0px;
    }
    .nws-land-page .news-item {
        padding-right: 0px;
    }
    .nws-land-page .nws-grd {
        margin-bottom: 40px !important;
    }
}

@media screen and (min-width:320px) and (max-width:1024px){
    .nws-land-page .cap_tabs_sec li a{
        font-size: 30px;
    }
    
    .nws-land-page .nws-dates {
        width: 100%;    
        align-items: center;
        justify-content: center;
    }
    .nws-land-page {
        padding-left: 20px;
        padding-right: 20px;
    }
}
.arabic-pg .abt-col1 {
    padding-right: 180px;
    direction: rtl;
}
.arabic-pg .abt-col2 {
    padding-left: 60px;
    direction: rtl;
}
.arabic-pg ul.mission-list {
    direction: rtl;
}
.arabic-pg .vision-sld.abt-sld, .arabic-pg  .abt-sld h2 {
    direction: rtl;
}

.arabic-pg .about-sld .slick-dots {
    text-align: right;
}
.arabic-pg .dot {    
    float: right;    
    margin-right: 0px;
    margin-left: 15px;
}
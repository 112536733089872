#custmr_serv {
    padding-bottom: 0px;
}
div#custmr_serv_main div#get-in-touch {
    padding-top: 0px;
}

div#custmr_serv_main .news-item.nws-grd {
    margin-bottom: 0px !important;
}
#custmr_serv .job-detail {
    margin-top: 35px;
}

#custmr_serv .job-descrp:before {
    position: absolute;
    width: 170px;
    background-color: rgb(0 0 0 / 10%);
    height: 2px;
    top: 0;
    left: 0;
    display: block;
    content: "";
}
#custmr_serv .job-descrp {
    position: relative;
    padding-top: 15px;
}

h5.job-descrp.main-descrp:before {
    background: none !important;
}

#custmr_serv h2 {
    color: #c13529;
}

#custmr_serv .nws-grd {
    float: none;
}

@media screen and (min-width:320px) and (max-width:1024px) {
    #custmr_serv .news-item {
        padding-right: 0px;
    }
}

@media screen and (min-width:320px) and (max-width:1024px) {
    #custmr_serv {
        padding: 30px 30px;
    }
}

@media screen and (min-width:320px) and (max-width:767px) {
    #custmr_serv .job-descrp:before {
        position: absolute;
        width: 90%;
        background-color: rgb(0 0 0 / 10%);
        height: 2px;
        top: 0;
        left: 0;
        display: block;
        content: "";
    }
}
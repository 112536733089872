.sector-main
{
    margin: 0px;
    padding-top:100px;
    padding-bottom:100px;
    min-height: calc(100vh - 91px);
    align-content: center;
}

.sec-sld-col1 .dot {
    width: 12px;
    height: 12px;
    background: #fff;
    margin-right: 15px;
    float: left;
    border-radius: 50%;
    margin-top: 3px;
}
.sec-sld-col1 .sec-name-ttl {
    font-family: 'Open Sans';
    font-weight: 400;
    font-size: 16px;
    letter-spacing: -0.015em;
    color: #fff;
    margin-bottom: 15px;
}
/*-------------------------------------------------------------------- Slider Css Start Here */
.sectr-sld-contnt {
    float: left;
    width: 100%;
}

.sector-sld {
    margin-right: 50px;
    position: relative;
}
.sec-hd
{
    position: relative;
}
.sec-subttl
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 30px;
    line-height: 39px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    position: absolute;
}
.sec-sub-cont
{
    position: absolute;
    top: 28%;
    /* bottom: 100px; */
}

.sec-sld-ttl
{
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 80px;
    line-height: 89px;
    letter-spacing: -0.015em;
    color: #FAFAFA;
}
.sec-para
{
    font-family: 'Open Sans';
    font-weight: 300;
    font-size: 18px;
    line-height: 32.4px;
    letter-spacing: -0.015em;
    width: 37%;
    color: #fff;
    margin-bottom: 20px;
}
.sec-read-btn, a.sec-read-btn:hover
{
    font-family: 'Montserrat';
    font-weight: 700;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.015em;
    color: #FFFFFF;
    padding-bottom: 6px;
    border-bottom: 2px solid #fff;
    text-decoration: none;
}
.sld-counter
{
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    /* line-height: 100%; */
    color: #FFFFFF;
    letter-spacing: 1px;
    position: absolute;
    top: auto;
    bottom: 10px;
    left: 415px;
}
.sld-counter > span
{
    font-weight: 600;
    font-size: 33px;
}

.sector-sld .slick-prev {
    
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #fff;
    border-radius: 50%;
    z-index: 1;
    top: auto;
    right: 75px;
    left:auto;
    bottom:-5px;
}
.sector-sld .slick-prev:before
{
    content: '' ;
    background-image: url('../img/left-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;          
}
.sector-sld .slick-arrow:hover 
{
    background-color: #fff;
    transition:0.3s all ease-in;
}
.sector-sld .slick-prev:hover:before
{
    background-image: url('../img/black-left-arrow.png');
}
.sector-sld .slick-next:hover:before
{
    background-image: url('../img/black-right-arrow.png');
}
.sector-sld .slick-next {
    
    color: #000;
    width: 58px;
    height: 58px;
    border: 1px dashed #fff;
    border-radius: 50%;
    top: auto;
    left: auto;
    right: 0px;
    bottom: -5px;
    z-index: 1;
}
.sector-sld .slick-next:before {
    content: '';
    background-image: url('../img/right-arrow.png');
    background-repeat: no-repeat;
    background-size: contain;
    width: 15px;
    height: 15px;
    align-self: center;
    display: flex;
    margin: auto;
}

.sector-sld .slick-slide.slick-current .sec-img
{
    transform: scale(1);
    filter: contrast(1);
}
.sec-img
{
    border-radius: 50%;
    float: right;
    transform: scale(0);
    transition: 1.5s all;
    filter: contrast(0.1);
    /* height: 60vh; */
}


/*-------------------------------------------------------------------- Tabs Css Start Here */
.sector-sld-nav .slick-slide.slick-current .nv-ttl
{
    opacity: 1;
}
.col-lg-2.sec-sld-col2 {
    align-self: auto;
}
.sector-sld-nav .slick-track {
    height: fit-content !important;
}
.sector-sld-nav .nv-ttl
{
    font-family: 'Montserrat';
    font-weight: 400;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2px;
    color: #fff;
    padding-right: 8px;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #FFFFFF;
    opacity:0.4;
    cursor: pointer;
}
.hardware-ttl
{
    border:none !important;
}
.sector-sld-nav .nv-ttl:hover {
    color: #fff !important;
    opacity: 1 !important;
}
.slick-slide.slick-active.slick-current {
    z-index: 999999;
}

@media screen and (min-width:1024px) and (max-width:1279px) {
    .sector-main {    
        min-height: calc(60vh - 91px);    
    }
    
    .sec-img {
        height: 40vh;
    }
    
}

@media screen and (min-width:320px) and (max-width:768px) {
    .sec-sub-cont {
        top: auto;
    bottom: 100px;
    }
}

@media screen and (min-width:1024px) and (max-width:1366px) {
    .home-sectr {
        padding-top: 50px;
    }    
}


    
    #arabic-pg .sld-cnt {
        direction: rtl;
        font-family: 'Noto Sans Arabic', sans-serif;
    }

    #arabic-pg .spt-btn {
        float: right;
    }

    #arabic-pg .spt-ttl {
        font-family: 'Noto Sans Arabic', sans-serif;
    }

a.apply-nw {
    width: 175px;
    height: 50px;
    font-weight: 600;
    font-size: 16px;
    font-family: 'Montserrat';
    line-height: 20px;
    display: flex;
    background: #CA2D1D;
    align-items: center;
    justify-content: center;
    color: #fff;
    text-decoration: none;
    margin-top: 20px;
    border: 3px solid #ca2d1d;
    transition: all .6s;
}

a.apply-nw:hover {
    background-color: #fff;
    color: #ca2d1d;
    border: 3px solid #ca2d1d !important;
}

.ul-styl {
    padding: 10px 20px;
    margin: 0;
}

h5.job-descrp {
    margin-bottom: 0;
    padding-top: 10px;
}

@media screen and (min-width: 320px) and (max-width:768px) {
    .currnt-opng {
        padding: 50px 20px;
        text-align: center;
    }
    .currnt-opng .news-item {
        padding-right: 0px;
        width: 100%;
        padding-bottom: 40px;
        margin-bottom: 40px!important;
    }
    .currnt-opng .ul-styl {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-content: center;
        justify-content: center;
        align-items: center;
    }
    a.apply-nw {
        width: auto;
    }
}

@media screen and (min-width:769px) and (max-width:1024px) {
    .currnt-opng {
        padding: 50px 35px;
    }
}
